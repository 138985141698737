import { useAppDispatch } from '@/hooks';
import { Switch, Tooltip } from 'antd';
import { AxiosResponse } from 'axios';
import classNames from 'classnames';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { get } from 'lodash';
import { Dispatch, SetStateAction, useEffect, useMemo, useRef, useState } from 'react';
import { FieldPath, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLoaderData, useNavigate, useParams } from 'react-router-dom';

import { getFirstErrorName } from '@pages/project-management/add-edit/scenario/utils';
import QuestionContent from '@pages/project-management/project-questionaire/components/question-detail/compononents/QuestionContent';
import QuestionTable from '@pages/project-management/project-questionaire/components/question-detail/compononents/QuestionTable';
import StickyButtons from '@pages/project-management/project-questionaire/components/question-detail/compononents/StickyButtons';
import {
  COPY_ACTION_CELL_WIDTH,
  DISPLAY_BUTTON_BY_VERSION,
  QUESTION_ACTION_CELL_WIDTH,
  QUESTION_TITLE_CELL_WIDTH,
  TRANSLATE_CELL_WIDTH,
  TYPE_SHARE_DATA_BETWEEN_LANGUAGES
} from '@pages/project-management/project-questionaire/components/question-detail/constants';
import { clearAnswer, isAnswered, jsonObjToString } from '@pages/project-management/project-questionaire/components/question-detail/helpers';
import {
  CONTENT_TYPE,
  IQuestion,
  IQuestionCategoryRecord,
  IQuestionOption,
  IQuestionRecord,
  IQuestionnaire,
  IQuestionnaireAnswer,
  IQuestionnaireAnswerUpsert,
  IQuestionnaireBrief
} from '@pages/project-management/project-questionaire/components/question-detail/modals';
import { HOLD_LOCK_CONFIRM_TIME, QUESTION_VERSIONS } from '@pages/project-management/project-questionaire/constanst';

import { ConfirmCancelEditing } from '@/components/confirm-popup';
import { BaseButton } from '@components/base-button/BaseButton';
import { TYPE_ICON, hideBasePopup, showBasePopup } from '@components/base-popup/BasePopup';
import { IFetchColumnType } from '@components/common/table-base';
import { SelectCustom } from '@components/form-select/SelectCustom';

import { setAlertNotification, setLoadingPage } from '@redux/globalReducer';
import { setQuestionnaireConfirmLock } from '@redux/questionnaire/questionnaireReducer';
import { selectQuestionnaireLockConfirm } from '@redux/questionnaire/questionnaireSelector';

import { C, V } from '@hooks/auth/constants';
import {
  DISPLAY_SETTING_PERMISSION,
  EXPORT_CSV_PERMISSION,
  TRANSLATE_PERMISSION,
  UNLOCK_SUBMIT_PERMISSION
} from '@hooks/auth/questionnairePermission';
import useAuthorization from '@hooks/useAuthorization';
import useMutation from '@hooks/useMutation';
import useResize from '@hooks/useResize';

import { DataViewer } from '@/utils/helpers/common';
import { parseJson } from '@/utils/helpers/globalHelper';
import { IRouterLoaderResponse } from '@/utils/helpers/routeLoaders';
import { API } from '@utils/constants/Apis';
import { FORMAT_DATE_EN_HH_MM_SS, TYPE } from '@utils/constants/AppConstants';
import { HTTP_STATUS_CODE } from '@utils/constants/Http';
import { EXTERNAL_QUESTION_LIST_URL, PROJECT_QUESTION_LIST_URL } from '@utils/constants/RouteContants';
import { downloadSingleFileFromURI } from '@utils/helpers/fileHelper';

import LockKeyIcon from '@/assets/icons/LockKey.svg';
import CheckIcon from '@assets/icons/CheckAvail.svg';
import CloseIcon from '@assets/icons/CloseIcon.svg';
import DownloadIcon from '@assets/icons/IconDownload.svg';
import ArrowClockWiseIcon from '@assets/icons/questions/ArrowsClockwise.svg';

import { QuestionPDF } from './QuestionPDF';

type Props = {
  isExternalQuestion?: boolean; // external question or not
  question: IQuestionnaire | null;
  loading: boolean;
  loadingSaveQuestionnaire: boolean;
  fetchData: () => void;
  saveQuestion: (payload: IQuestionnaireAnswer) => Promise<AxiosResponse | null | undefined>;
  setQuery?: Dispatch<SetStateAction<any>>;
};
export const QuestionDetail = (props: Props) => {
  const { isExternalQuestion, question: dataQuestionnaire, loading, loadingSaveQuestionnaire, fetchData, saveQuestion, setQuery } = props;
  const loaderData = (useLoaderData() as IRouterLoaderResponse<any, any>) ?? {};

  const { t } = useTranslation();
  const navigate = useNavigate();
  const tableRef = useRef<any>(null);
  const pdfRenderRef = useRef<any>(null);
  const unlockKeyRef = useRef<any>(null);
  const disableUnlockRef = useRef(false);
  const dispatch = useAppDispatch();

  const { isInternalRole, hasPermission, user } = useAuthorization();
  const { id: projectId, formId, token = '' } = useParams();
  const [initFlg, setInitFlg] = useState(true);
  const lockConfirm = useSelector(selectQuestionnaireLockConfirm);

  const questionnaireVersion = dataQuestionnaire?.questionnaireVersion ?? QUESTION_VERSIONS.NONE;
  const isLatestVersion = !(
    dataQuestionnaire?.questionnaireVersions?.includes(QUESTION_VERSIONS.UPDATED) &&
    [QUESTION_VERSIONS.NONE, QUESTION_VERSIONS.ORIGINAL].includes(questionnaireVersion)
  );
  const isExternalLock = (!isInternalRole || isExternalQuestion) && dataQuestionnaire?.lock;

  const form = useForm<IQuestionnaire>({
    mode: 'all',
    shouldUnregister: false
  });
  const { isDirty } = form.formState;

  const { mutate: lockQuestionnaire, error: errorLock } = useMutation(API.POST_QUESTIONNAIRE_LOCK(projectId ?? '', formId ?? ''), {
    method: 'POST',
    showToastError: true
  });
  const { mutate: unlockQuestionnaire } = useMutation(API.POST_QUESTIONNAIRE_UNLOCK(projectId ?? '', formId ?? ''), {
    method: 'POST'
  });

  const { mutate: exportCsvQuestionnaire, loading: loadingExportCsv } = useMutation(
    API.POST_QUESTIONNAIRE_EXPORT_CSV(projectId ?? '', formId ?? ''),
    {
      method: 'POST',
      showToastError: true
    },
    {
      version: questionnaireVersion
    }
  );

  const { mutate: unlockSubmit, loading: loadingUnlockSubmit } = useMutation(API.PUT_QUESTIONNAIRE_UNLOCK_SUBMIT(projectId ?? '', formId ?? ''), {
    method: 'PUT',
    showToastError: true,
    showToastSuccess: true,
    defaultSuccessMsg: t('MSG_C_003') ?? '',
    paramMsg: { item: t('project:question_detail:buttons:unlock_submit') }
  });

  const { mutate: getAnswer } = useMutation(API.GET_QUESTIONNAIRE_ANSWER, {
    method: 'GET',
    showToastError: false,
    showToastSuccess: false
  });

  const copyAnswerFromApplicant = async (checked: boolean, fieldNames: string[]) => {
    fieldNames.forEach((fieldName) => {
      form.setValue(`${fieldName}.answer.isCopyAnswer` as FieldPath<IQuestionnaire>, checked, {
        shouldDirty: true,
        shouldValidate: true,
        shouldTouch: true
      });
      if (!checked) {
        clearAnswer(form, fieldName);
      }
    });

    if (!checked) {
      return;
    }

    await Promise.all(
      fieldNames.map(async (fieldName) => {
        const question = form.getValues(`${fieldName}` as FieldPath<IQuestionnaire>);
        const questionId = question?.id ?? '';
        const res = await getAnswer({}, { projectId: projectId ?? '', questionId });
        if (res?.data) {
          const { answered, answeredEn } = res?.data;
          const parseAnswered = parseJson(answered) ?? answered ?? null;
          const parseAnsweredEn = parseJson(answeredEn) ?? answeredEn ?? null;
          if (!parseAnswered) {
            clearAnswer(form, fieldName);
            return;
          }

          // With type checkbox and radio, couldn't get id directly, have to compare title of option
          if ([CONTENT_TYPE.CHECKBOX, CONTENT_TYPE.RADIO, CONTENT_TYPE.DROPDOWN].includes(question.type)) {
            const titles = parseAnswered instanceof Array ? parseAnswered.map((i) => i.title) : [parseAnswered?.title];
            const selectedItems = (question.options as IQuestionOption[])?.filter((i: any) => titles.includes(i.title));
            if (question.type === CONTENT_TYPE.RADIO || question.type === CONTENT_TYPE.DROPDOWN) {
              selectedItems?.forEach((item) => {
                form.setValue(
                  `${fieldName}.answer.answered` as FieldPath<IQuestionnaire>,
                  { id: item.id, title: item.title, label: parseAnswered?.label },
                  {
                    shouldDirty: true,
                    shouldValidate: true,
                    shouldTouch: true
                  }
                );
                form.setValue(
                  `${fieldName}.answer.answeredEn` as FieldPath<IQuestionnaire>,
                  { id: item.id, title: item.titleEn ?? item.title, label: parseAnsweredEn?.label },
                  {
                    shouldDirty: true,
                    shouldValidate: true,
                    shouldTouch: true
                  }
                );
              });
            }
            if (question.type === CONTENT_TYPE.CHECKBOX || question.type === CONTENT_TYPE.MULTI_DROPDOWN) {
              form.setValue(
                `${fieldName}.answer.answered` as FieldPath<IQuestionnaire>,
                selectedItems.map((i) => i.id)
              );
              form.setValue(
                `${fieldName}.answer.answeredEn` as FieldPath<IQuestionnaire>,
                selectedItems.map((i) => i.id)
              );
            }
            return;
          }

          // With type unit select, couldn't get id of unit directly, have to compare title of unit
          if (question.type === CONTENT_TYPE.UNIT_SELECT) {
            const selectedUnitItem = (question.config?.units as IQuestionOption[])?.find((i: any) => parseAnswered?.unit?.title === i.title);
            if (selectedUnitItem) {
              form.setValue(`${fieldName}.answer.answered` as FieldPath<IQuestionnaire>, {
                ...parseAnswered,
                unit: {
                  id: selectedUnitItem.id,
                  title: selectedUnitItem.title
                }
              });
              form.setValue(`${fieldName}.answer.answeredEn` as FieldPath<IQuestionnaire>, {
                ...parseAnsweredEn,
                unit: {
                  id: selectedUnitItem.id,
                  title: selectedUnitItem.titleEn ?? selectedUnitItem.title
                }
              });
            }

            return;
          }

          if (question.type === CONTENT_TYPE.YEAR_MONTH) {
            form.setValue(`${fieldName}.answer.answered` as FieldPath<IQuestionnaire>, parseAnswered, {
              shouldDirty: true,
              shouldValidate: true,
              shouldTouch: true
            });
            return;
          }

          if (CONTENT_TYPE.TEXT_MULTI === question.type) {
            parseAnswered.forEach((a: any, index: number) => {
              const answered = parseJson(a) ?? a ?? null;
              const type = question.config[index]?.type;
              if ([CONTENT_TYPE.CHECKBOX, CONTENT_TYPE.RADIO, CONTENT_TYPE.DROPDOWN].includes(type)) {
                const config = question.config[index];
                const titles = answered instanceof Array ? answered.map((i) => i.title) : [answered?.title];
                const selectedItems = (config.options as IQuestionOption[])?.filter((i: any) => titles.includes(i.title));
                if (type === CONTENT_TYPE.RADIO || type === CONTENT_TYPE.DROPDOWN) {
                  const selectedItem = selectedItems[0];
                  if (selectedItem) {
                    form.setValue(
                      `${fieldName}.answer.answered.${index}` as FieldPath<IQuestionnaire>,
                      { id: selectedItem.id, title: selectedItem.title, label: answered?.label },
                      {
                        shouldDirty: true,
                        shouldValidate: true,
                        shouldTouch: true
                      }
                    );
                    form.setValue(
                      `${fieldName}.answer.answeredEn.${index}` as FieldPath<IQuestionnaire>,
                      { id: selectedItem.id, title: selectedItem.titleEn, label: answered?.label },
                      {
                        shouldDirty: true,
                        shouldValidate: true,
                        shouldTouch: true
                      }
                    );
                    return;
                  }
                }
                if (question.type === CONTENT_TYPE.CHECKBOX) {
                  form.setValue(
                    `${fieldName}.answer.answered.${index}` as FieldPath<IQuestionnaire>,
                    selectedItems.map((i) => i.id)
                  );
                  form.setValue(
                    `${fieldName}.answer.answeredEn.${index}` as FieldPath<IQuestionnaire>,
                    selectedItems.map((i) => i.id)
                  );
                  return;
                }
              }

              form.setValue(`${fieldName}.answer.answered.${index}` as FieldPath<IQuestionnaire>, answered, {
                shouldDirty: true,
                shouldValidate: true,
                shouldTouch: true
              });
              if (parseAnsweredEn) {
                const answeredEn = parseJson(parseAnsweredEn[index] ?? '') ?? parseAnsweredEn[index] ?? null;

                form.setValue(`${fieldName}.answer.answeredEn.${index}` as FieldPath<IQuestionnaire>, answeredEn, {
                  shouldDirty: true,
                  shouldValidate: true,
                  shouldTouch: true
                });
              }
            });
            return;
          }

          form.setValue(`${fieldName}.answer.answered` as FieldPath<IQuestionnaire>, parseAnswered, {
            shouldDirty: true,
            shouldValidate: true,
            shouldTouch: true
          });
          form.setValue(`${fieldName}.answer.answeredEn` as FieldPath<IQuestionnaire>, parseAnsweredEn, {
            shouldDirty: true,
            shouldValidate: true,
            shouldTouch: true
          });
        }
      })
    );
  };

  const equalizeTdHeights = (rows: any[]) => {
    // Fixed td height when title is too long
    rows?.forEach((row: HTMLElement) => {
      const titleCell = row.querySelector('td:first-child');
      const questionContentCell = row.querySelector('td:nth-child(2)>div');
      if (!titleCell || !questionContentCell) {
        return;
      }
      if (titleCell?.clientHeight - questionContentCell?.clientHeight > 3) {
        questionContentCell.style.height = `${titleCell?.clientHeight - 1}px`;
      }
    });

    const originTds = tableRef.current.querySelectorAll(`.origin-data td:not(.additional-cell)`);
    const translateTds = tableRef.current.querySelectorAll(`.translate-data td:not(.additional-cell)`);
    if (!originTds.length || !translateTds.length) {
      return;
    }

    // Assuming both sets have the same length
    for (let i = originTds.length - 1; i >= 0; i--) {
      const originHeight = originTds[i].clientHeight;
      const translateHeight = translateTds[i].clientHeight;
      const maxHeight = Math.max(originHeight, translateHeight);

      // // Set both tds to the maxHeight
      if (originHeight !== translateHeight) {
        originTds[i].style.height = `${maxHeight}px`;
        translateTds[i].style.height = `${maxHeight}px`;
      }
    }
  };

  const { loading: loadingTranslate, mutate: translate } = useMutation(API.PUT_QUESTIONNAIRE_TRANSLATE(projectId ?? '', formId ?? ''), {
    method: 'PUT',
    showToastError: true
  });
  const [dataSource, setDataSource] = useState<IQuestionCategoryRecord[] | undefined>(undefined);
  const [dataSourcePDF, setDataSourcePDF] = useState<IQuestionCategoryRecord[] | undefined>([]);
  const [downloadPdfFlg, setDownloadPdfFlg] = useState(false);

  const size = useResize({ elRef: tableRef });
  const showTranslationData =
    !isExternalQuestion &&
    hasPermission(TRANSLATE_PERMISSION, V) &&
    questionnaireVersion !== QUESTION_VERSIONS.NONE &&
    dataQuestionnaire?.isTranslated;

  const needReRender = (fieldName: string) => {
    if (!fieldName) {
      return false;
    }

    if (['isCopyAnswer'].includes(fieldName.split('.').pop() ?? '')) {
      return true;
    }

    const matches = /(.*).(answer.answered.id$)/.exec(fieldName);
    if (matches && matches[1]) {
      const item = form.getValues(matches[1] as FieldPath<IQuestionnaire>);
      if (item.type === CONTENT_TYPE.RADIO) {
        return true;
      }
    }

    const matchedVisibleExternalUser = /(.*)\.visibleExternalUser$/.test(fieldName);
    if (matchedVisibleExternalUser) {
      return true;
    }

    return false;
  };

  useEffect(() => {
    if (!loaderData?.preCheck404?.data) {
      return;
    }
    const data = loaderData?.preCheck404.data as IQuestionnaireBrief;
    if (!isExternalQuestion && !data.lockQuestionnaire && !data.lock) {
      lockQuestionnaire({});
      return;
    }
    if (!data.lock && data.lockQuestionnaire && data.lockBy !== user?.id) {
      dispatch(
        setAlertNotification({
          show: true,
          type: 'error',
          message: t('common:MSG_P_035')
        })
      );
      setTimeout(() => backToList(), 0);
    }
  }, [loaderData]);

  useEffect(() => {
    if (dataQuestionnaire) {
      const categories = dataQuestionnaire.questionCategories.sort((a, b) => a.displayOrder - b.displayOrder) ?? [];
      let questionIndex = 1;

      // Reset invisibleExternalUser list path when first load
      inVisibleExUserPaths.current = [];
      const questionCategories = categories?.map((category, cIndex) => {
        let children = serializeDataQuestion(category?.questions ?? [], `questionCategories.${cIndex}.questions`);
        const childrenConverted = children.map((i) => ({
          ...i,
          categoryIndex: cIndex,
          no: !i.visibleExternalUser || i.type === CONTENT_TYPE.NONE ? undefined : questionIndex++
        }));
        return {
          ...category,
          updatedTime: new Date(),
          children: childrenConverted
        };
      });
      form.reset({
        questionCategories
      });
      const newTime = new Date();
      const newDataSource = questionCategories.map((i, cIndex) => ({
        name: i.name,
        index: cIndex,
        id: cIndex,
        children: i.children?.map((j) => ({
          id: j.id,
          no: j.no,
          title: j.title,
          translateFlag: false,
          visibleExternalUser: j.visibleExternalUser,
          updatedTime: newTime,
          categoryIndex: cIndex,
          type: j.type
        }))
      })) as IQuestionCategoryRecord[];
      setDataSource(newDataSource);
      unlockKeyRef.current = dataQuestionnaire.unlockKey;
      disableUnlockRef.current = isExternalQuestion || dataQuestionnaire.lock;
      setInitFlg(false);
    }
  }, [dataQuestionnaire, isExternalQuestion]);

  useEffect(() => {
    setTimeout(() => equalizeTdHeights(tableRef?.current?.querySelectorAll('.ant-table-row') ?? []), 2000);
  }, [dataQuestionnaire]);

  useEffect(() => {
    if (!lockConfirm) return;
    if (lockConfirm && lockConfirm.objectId === formId) {
      confirmLockQuestionnaire();
    }
  }, [lockConfirm]);

  useEffect(() => {
    if (errorLock) {
      setTimeout(() => backToList(), 1000);
    }
  }, [errorLock]);

  useEffect(() => {
    // Show loading data
    let showLoading = true;
    if (!(loading || loadingTranslate)) {
      showLoading = false;
    }
    if (!showLoading) {
      setTimeout(() => {
        dispatch(setLoadingPage(showLoading));
      }, 0);
    }
  }, [loading, loadingTranslate]);

  /**
   * handle check visibleExternalUser
   * @link https://jira.vmo.dev/browse/MOI24024-3911
   */
  const checkVisibleExternalBy = (form: any, fieldName: string) => {
    const isSwitchVisibleExternalUser = /(.*)\.visibleExternalUser$/.test(fieldName);
    if (!isSwitchVisibleExternalUser) return;
    const parentPath = fieldName.replace(/\.visibleExternalUser$/g, '');
    const updatedData = get(form, parentPath);
    const fieldValue = updatedData?.visibleExternalUser;
    const autoSwitch = (options: any) => {
      if (!options?.length) return;
      options.forEach((op: any) => {
        if (op?.hasOwnProperty('visibleExternalUser')) {
          op.visibleExternalUser = fieldValue;
          op._disabled = !fieldValue;
        }
        if (op?.questions?.length) autoSwitch(op.questions);
        if (op?.options?.length) autoSwitch(op.options);
      });
    };
    autoSwitch(updatedData?.options);
    autoSwitch(updatedData?.questions);
  };

  useEffect(() => {
    const subscription = form.watch((_dataForm, { name: fieldName }) => {
      if (initFlg) {
        return;
      }
      const keyName = (fieldName ?? '') as FieldPath<IQuestionnaire>;
      const isNotTranslateAnswer = !fieldName?.includes('answeredEn');
      const keyTranslate = keyName?.replace('.answered', '.answeredEn');
      const matches = /questionCategories.([0-9]+).children.([0-9]+)/.exec(fieldName ?? '');

      if (isNotTranslateAnswer) {
        addWarning(keyTranslate);
        keyName !== keyTranslate && form.setValue(keyTranslate, null);
      }
      if (isAnswered(form.getValues(keyName))) {
        removeWarning(keyName);
      }
      if (matches && matches.length) {
        if (!needReRender(keyName)) {
          return;
        }
        const categoryIndex = parseInt(matches[1] ?? 0);
        const questionIndex = parseInt(matches[2] ?? 0);
        const newDataSource = structuredClone(dataSource);

        const parentType = get(_dataForm, `questionCategories.${categoryIndex}.children.${questionIndex}.type`);
        if (parentType && parentType !== CONTENT_TYPE.QUESTION_ONLY) {
          checkVisibleExternalBy(_dataForm, keyName);
        }

        const item = newDataSource?.[categoryIndex].children?.[questionIndex];
        if (!item) {
          updateHeight(keyName);
          return;
        }
        item.updatedTime = new Date();
        setDataSource(newDataSource);
        updateHeight(keyName);
      }
    });
    return () => {
      subscription.unsubscribe();
    };
  }, [form.watch, isDirty, initFlg]);

  const handleUnlockQuestionnaire = async () => {
    if (disableUnlockRef.current) {
      return;
    }
    await unlockQuestionnaire({
      unlockQuestionnaire: true,
      unlockKey: unlockKeyRef.current ?? ''
    });
  };

  useEffect(() => {
    return () => {
      // Unlock questionnaire on leave
      handleUnlockQuestionnaire();
    };
  }, []);

  const backToList = async (forceBack: boolean = false) => {
    try {
      if (!forceBack) {
        await handleUnlockQuestionnaire();
        // Remove lock notification
        if (formId === lockConfirm?.objectId) {
          dispatch(setQuestionnaireConfirmLock(null));
        }
      }
    } catch (error) {}
    const backUrl = isExternalQuestion ? EXTERNAL_QUESTION_LIST_URL(token) : PROJECT_QUESTION_LIST_URL(projectId ?? '');
    if (forceBack) {
      window.location.href = backUrl;
    } else {
      navigate(backUrl);
    }
  };

  const unlockSubmitQuestionnaire = async () => {
    const res = await unlockSubmit({});
    if (res?.status === HTTP_STATUS_CODE.SUCCESS) {
      // update user lock access questionnaire
      lockQuestionnaire({});
      setQuery?.({});
    }
  };

  const cancelQuestionnaire = async () => {
    const showPopup = await showBasePopup({
      title: String(t('common:MSG_C_002.title')),
      msg: String(t('common:MSG_C_002.description')),
      type: TYPE_ICON.CONFIRM
    });

    if (showPopup === TYPE_ICON.CONFIRM) {
      refreshData();
    }
  };

  const compareChangeRecord = (record: IQuestionRecord, prevRecord: IQuestionRecord) => {
    return record.id !== prevRecord.id || record.updatedTime !== prevRecord.updatedTime;
  };

  const countQuestionAnswer = (question: IQuestion): { question: number; answer: number } => {
    if (!question.visibleExternalUser && question.level === 1) {
      return {
        question: 0,
        answer: 0
      };
    }

    let questionCount = 0;
    let answerCount = 0;
    if (question.needToAnswer && ![CONTENT_TYPE.QUESTION_ONLY, CONTENT_TYPE.NONE].includes(question.type)) {
      questionCount++;
      if (isAnswered(question.answer?.answered)) {
        answerCount++;
      }
    }

    question.questions?.forEach((q) => {
      const { question: qChildrenCount, answer: aChildrenCount } = countQuestionAnswer(q);
      questionCount += qChildrenCount;
      answerCount += aChildrenCount;
    });

    if (question.options?.length) {
      const selected: (string | null)[] = [];
      if (question.type === CONTENT_TYPE.RADIO && question.answer?.answered?.id) {
        selected.push(question.answer?.answered?.id ?? null);
      }
      if (question.type === CONTENT_TYPE.CHECKBOX && question.answer?.answered?.id) {
        selected.push(...question.answer?.answered?.map((i: any) => i.id));
      }
      question.options?.forEach((o) => {
        if (selected.includes(o.id) && o.questions?.length) {
          o.questions?.forEach((q) => {
            const { question: qChildrenCount, answer: aChildrenCount } = countQuestionAnswer(q);
            questionCount += qChildrenCount;
            answerCount += aChildrenCount;
          });
        }
      });
    }

    return { question: questionCount, answer: answerCount };
  };

  const findInputsNotAnswer = (question: IQuestion, parentName: string = ''): string[] => {
    if (!question.visibleExternalUser && question.level === 1) {
      return [];
    }
    const inputNames = [];
    if (question.needToAnswer && ![CONTENT_TYPE.QUESTION_ONLY, CONTENT_TYPE.NONE].includes(question.type)) {
      if (!isAnswered(question.answer?.answered)) {
        inputNames.push(`${parentName}.answer.answered`);
      }
    }

    question.questions?.forEach((q, qIndex) => {
      inputNames.push(...findInputsNotAnswer(q, `${parentName}.questions.${qIndex}`));
    });

    if (question.options?.length) {
      const selected: (string | null)[] = [];
      if (question.type === CONTENT_TYPE.RADIO && question.answer?.answered?.id) {
        selected.push(question.answer?.answered?.id ?? null);
      }
      question.options?.forEach((o, oIndex) => {
        if (selected.includes(o.id) && o.questions?.length) {
          o.questions?.forEach((q, qIndex) => {
            inputNames.push(...findInputsNotAnswer(q, `${parentName}.options.${oIndex}.questions.${qIndex}`));
          });
        }
      });
    }

    return inputNames;
  };

  const updateHeight = (inputName: string) => {
    const parent = document.querySelector(`[name*="${inputName}"]`)?.closest('.ant-table-row');
    const element = parent?.querySelector('td:nth-child(2)>div');
    if (element) {
      element.style.removeProperty('height');
    }
    if (parent) {
      setTimeout(() => equalizeTdHeights([parent]), 500);
    }
  };

  const removeWarning = (inputName: string) => {
    document.querySelectorAll(`[name*="${inputName}"]`).forEach((element) => {
      if (element.classList.contains('warning-input')) {
        element.classList.remove('warning-input');
      }
    });
  };

  const addWarning = (inputName: string) => {
    document.querySelectorAll(`[name="${inputName}"]`).forEach((element) => {
      if (!element.classList.contains('warning-input')) {
        element.classList.add('warning-input');
      }
    });
  };

  const highlightQuestionNotAnswer = () => {
    const questionCategories = form.getValues('questionCategories') ?? [];
    const inputNames: string[] = [];
    questionCategories.forEach((category, cIndex) => {
      (category?.children ?? []).forEach((question, qIndex) => {
        inputNames.push(...findInputsNotAnswer(question, `questionCategories.${cIndex}.children.${qIndex}`));
      });
    });

    if (!inputNames.length) {
      dispatch(
        setAlertNotification({
          show: true,
          type: TYPE.SUCCESS,
          message: t(`common:MSG_P_024`)
        })
      );
      return;
    }

    inputNames.forEach((inputName) => {
      document.querySelectorAll(`[name*="${inputName}"]`).forEach((element) => {
        if (!element.classList.contains('warning-input')) {
          element.classList.add('warning-input');
        }
      });
    });

    // Scroll to the first input found
    const firstInput = document.querySelector(`[name*="${inputNames[0]}"]`);
    if (firstInput) {
      firstInput?.closest('td')?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
      // return;
    }
  };

  const removeOptions = (obj: any): any => {
    if (!obj) {
      return obj;
    }
    if (typeof obj !== 'object') {
      return obj;
    }
    if (obj instanceof Array) {
      return obj.map((i) => removeOptions(i));
    }
    const newObj = { ...obj };
    if (newObj && typeof newObj === 'object') {
      if (newObj['options']) {
        delete newObj.options;
      }

      for (const key in newObj) {
        if (newObj[key] && typeof newObj[key] === 'object') {
          newObj[key] = removeOptions(newObj[key]);
        }
      }
    }
    return newObj;
  };

  const serializedAnswerContent = (answerContent: any) => {
    if (!answerContent) return null;

    let content = structuredClone(answerContent);
    if (content && typeof content === 'object') {
      content = removeOptions(content);
    }

    if (!isAnswered(content)) {
      return null;
    }

    return content;
  };

  const gatherAnswer = (question: IQuestion): IQuestionnaireAnswerUpsert[] => {
    const answerArr: IQuestionnaireAnswerUpsert[] = [];
    if (question.answer) {
      let answered = serializedAnswerContent(question.answer.answered);
      let answeredEn = TYPE_SHARE_DATA_BETWEEN_LANGUAGES.includes(question.type)
        ? structuredClone(answered)
        : serializedAnswerContent(question.answer.answeredEn);

      // Check type text multi
      if (question.type === CONTENT_TYPE.TEXT_MULTI && answered?.length) {
        answered.forEach((ans: any, index: number) => {
          if (ans && answeredEn && typeof ans === 'object') {
            answeredEn[index] = { ...ans };
          }
        });
        if (answered?.length) {
          answered = answered.map((i: any) => jsonObjToString(serializedAnswerContent(i)));
        }

        if (answeredEn?.length) {
          answeredEn = answeredEn.map((i: any) => jsonObjToString(serializedAnswerContent(i)));
        }
      }

      // Check type Table
      if (question.type === CONTENT_TYPE.TABLE && answered?.length) {
        if (answered?.length) {
          answered = answered.map((row: any) => row.map((i: any) => jsonObjToString(serializedAnswerContent(i))));
        }

        if (answeredEn?.length) {
          answeredEn = answeredEn.map((row: any) => row.map((i: any) => jsonObjToString(serializedAnswerContent(i))));
        }
      }

      // Check type Checkbox
      if (question.type === CONTENT_TYPE.CHECKBOX) {
        if (answered?.length) {
          answered = answered.map((checkedValue: any) => ({
            id: checkedValue,
            title: question.options?.find((o) => o.id === checkedValue)?.title ?? ''
          }));
        }
        if (answeredEn?.length) {
          answeredEn = answeredEn.map((checkedValue: any) => ({
            id: checkedValue,
            title: question.options?.find((o) => o.id === checkedValue)?.titleEn ?? ''
          }));
        }
      }

      if (question.type === CONTENT_TYPE.DROPDOWN) {
        if (answered) {
          answeredEn = {
            ...answered
          };
        }
      }

      if (question.type === CONTENT_TYPE.MULTI_DROPDOWN) {
        answeredEn = answered;
        if (answered?.length) {
          answered = answered.filter(Boolean).map((checkedValue: any) => ({
            id: checkedValue,
            title: question.options?.find((o) => o.id === checkedValue)?.title ?? ''
          }));
        }
        if (answeredEn?.length) {
          answeredEn = answeredEn.filter(Boolean).map((checkedValue: any) => ({
            id: checkedValue,
            title: question.options?.find((o) => o.id === checkedValue)?.titleEn ?? ''
          }));
        }
      }

      answerArr.push({
        projectQuestionnaireId: question.id ?? '',
        answered: answered && typeof answered === 'object' ? jsonObjToString(answered) : answered ?? null,
        answeredEn: answeredEn && typeof answeredEn === 'object' ? jsonObjToString(answeredEn) : answeredEn ?? null,
        isCopyAnswer: question.answer?.isCopyAnswer ?? null,
        visibleExternalUser:
          question.type === CONTENT_TYPE.QUESTION_ONLY ? question.questions?.some((i) => i.visibleExternalUser) : question.visibleExternalUser
      });
    }

    question.questions?.forEach((q) => {
      answerArr.push(...gatherAnswer(q));
    });

    if ([CONTENT_TYPE.RADIO, CONTENT_TYPE.CHECKBOX].includes(question.type) && question.options?.length) {
      const selected = question.answer?.answered instanceof Array ? question.answer?.answered.map((i) => i.id) : [question.answer?.answered?.id];

      question.options?.forEach((o) => {
        if (selected.includes(o.id) && o.questions?.length) {
          o.questions?.forEach((q) => {
            answerArr.push(...gatherAnswer(q));
          });
        }
      });
    }

    return answerArr;
  };

  const saveQuestionnaire = async (isSubmit: boolean) => {
    form.handleSubmit(
      async (data) => {
        if (isSubmit) {
          const showPopup = await showBasePopup({
            title: !isInternalRole ? t('common:MSG_P_026.title') : t('common:MSG_P_038.title'),
            msg: !isInternalRole ? '' : t('common:MSG_P_038.content'),
            type: TYPE_ICON.CONFIRM
          });

          if (showPopup !== TYPE_ICON.CONFIRM) {
            return;
          }
        }

        const requestPayload: IQuestionnaireAnswer = {
          isSubmit,
          totalQuestion: 0,
          totalAnswer: 0,
          questionnaireSettingChanges: [],
          questionnaireAnswerUpserts: []
        };
        if (!isExternalQuestion) {
          requestPayload.version = dataQuestionnaire?.version;
        }
        const questionCategories = form.getValues('questionCategories') ?? [];
        questionCategories.forEach((category) => {
          (category.children ?? []).forEach((q) => {
            // if (isInternalRole) {
            //   requestPayload.questionnaireSettingChanges.push({
            //     id: q.id ?? '',
            //     visibleExternalUser: q.visibleExternalUser ?? false
            //   });
            // }
            // Count question and answer
            const { question: qCount, answer: aCount } = countQuestionAnswer(q);
            requestPayload.totalQuestion += qCount;
            requestPayload.totalAnswer += aCount;
            const answerData = gatherAnswer(q);

            if (isInternalRole) {
              requestPayload.questionnaireSettingChanges.push(
                ...answerData.map((q) => ({
                  id: q.projectQuestionnaireId,
                  visibleExternalUser: !!q.visibleExternalUser
                }))
              );
            }
            // Gather answer
            requestPayload.questionnaireAnswerUpserts.push(...answerData);
          });
        });
        const res = await saveQuestion(requestPayload);
        if (res?.status === HTTP_STATUS_CODE.SUCCESS) {
          const itemName = isInternalRole
            ? `${t('project:question_detail:buttons:submit_internal')}`
            : `${t('project:question_detail:buttons:submit_external')}`;
          dispatch(
            setAlertNotification({
              show: true,
              type: TYPE.SUCCESS,
              message: t('common:MSG_C_003', {
                item: isSubmit ? itemName : t('project:question_detail:buttons:save')
              })
            })
          );
          // Unlock access questionnaire in case submit
          isSubmit && handleUnlockQuestionnaire();
          refreshData();
        }
      },
      (errors) => {
        const errorName = getFirstErrorName(errors, {}, '');
        // convert format name from "parent[index].children" to "parent.{index}.children" to map with id
        const errorElement = document.getElementById(errorName.replace(/\[(\d+)]/g, '.$1'));
        if (!errorElement) {
          return;
        }
        if (['INPUT', 'TEXTAREA'].includes(errorElement.tagName)) {
          errorElement.focus();
          return;
        }
        errorElement.scrollIntoView({ block: 'center' });
      }
    )();
  };

  const columns: IFetchColumnType<IQuestionRecord>[] = useMemo(() => {
    let fixedWidth = QUESTION_TITLE_CELL_WIDTH + 1;
    if (hasPermission(TRANSLATE_PERMISSION, V) && !isExternalQuestion) {
      fixedWidth += TRANSLATE_CELL_WIDTH;
    }
    if (hasPermission(DISPLAY_SETTING_PERMISSION, V) && !isExternalQuestion) {
      fixedWidth += QUESTION_ACTION_CELL_WIDTH;
    }
    if (!!dataQuestionnaire?.familyId && !isExternalQuestion) {
      fixedWidth += COPY_ACTION_CELL_WIDTH;
    }
    const contentWidth = ((size?.width || 0) - fixedWidth) / (showTranslationData ? 2 : 1);
    const showTranslateFlag = !isExternalQuestion && hasPermission(TRANSLATE_PERMISSION, V);
    const showCopyButton = !isExternalQuestion && !!dataQuestionnaire?.familyId;
    const translateDataColumns: IFetchColumnType<IQuestionRecord>[] = showTranslationData
      ? [
          {
            title: t('project:question_detail:answer_translate'),
            key: 'answerTranslate',
            render: (_: any, record: IQuestionRecord, index: number) => null,
            ellipsis: true,
            colSpan: showTranslationData ? 1 : 0,
            onCell: () => {
              return {
                colSpan: 0
              };
            }
          }
        ]
      : [];

    const translateStatusColumns: IFetchColumnType<IQuestionRecord>[] = showTranslateFlag
      ? [
          {
            title: t('project:question_detail:translate_or_not'),
            width: TRANSLATE_CELL_WIDTH,
            className: `max-w-[${TRANSLATE_CELL_WIDTH}px] text-center`,
            key: 'translateFlag',
            align: 'center',
            render: (_: any, record: IQuestionRecord) => (
              <div className='flex items-center justify-center'>
                {record.translateFlag ? <CheckIcon className='icon' /> : <CloseIcon className='icon' />}
              </div>
            ),
            onCell: (_data: IQuestionRecord) => {
              return {
                colSpan: 0
              };
            }
          }
        ]
      : [];

    const actionColumns: IFetchColumnType<IQuestionRecord>[] =
      !isExternalQuestion && hasPermission(DISPLAY_SETTING_PERMISSION, V)
        ? [
            {
              title: t('project:question_detail:show_external'),
              key: 'visibleExternalUser',
              width: QUESTION_ACTION_CELL_WIDTH,
              className: `max-w-[${QUESTION_ACTION_CELL_WIDTH}px] text-center cell-action`,
              render: (_: any, record: IQuestionRecord, index) => (
                <Switch
                  onClick={(checked) => {
                    form.setValue(`questionCategories.${record?.categoryIndex || 0}.children.${index}.visibleExternalUser`, checked, {
                      shouldDirty: true
                    });
                  }}
                  defaultChecked={!!record.visibleExternalUser}
                  // checked={!!form.getValues(`questionCategories.${record?.categoryIndex || 0}.children.${index}.visibleExternalUser`)}
                />
              ),
              ellipsis: true,
              align: 'left',
              onCell: (_data: IQuestionRecord) => {
                return {
                  colSpan: 0
                };
              }
            }
          ]
        : [];

    const columnOptions: IFetchColumnType<IQuestionRecord>[] = [
      {
        title: t('project:question_detail:question'),
        width: QUESTION_TITLE_CELL_WIDTH,
        className: `max-w-[${QUESTION_TITLE_CELL_WIDTH}px] bg-green10 cell-question whitespace-pre-line`,
        render: (_value: any, record: IQuestionRecord, _index: number) => {
          return record.type !== CONTENT_TYPE.NONE ? (
            <div
              className={classNames(!record.visibleExternalUser ? 'header-text-disable' : '', 'body-700')}
            >{`${record.no ? `${record.no}. ` : ``}${record.title}`}</div>
          ) : (
            <></>
          );
        },
        tooltip: (_value: any, record: IQuestionRecord, _index: number) => record.title ?? '',
        ellipsis: false
      },
      {
        title: t('project:question_detail:answer'),
        key: 'answer',
        width: contentWidth,
        render: (_: any, record: IQuestionRecord, index: number) => {
          const formKey = `questionCategories.${record?.categoryIndex ?? -1}.children.${index}`;
          return (
            <QuestionContent
              key={record.id}
              question={form.getValues(formKey as FieldPath<IQuestionnaire>)}
              mode={(!isInternalRole && dataQuestionnaire?.lock) || !isLatestVersion ? 'view' : 'edit'}
              name={`questionCategories.${record?.categoryIndex}.children.${index}`}
              showTranslationData={showTranslationData}
              showTranslateFlg={showTranslateFlag}
              showCopyFlg={showCopyButton}
              isAncestor={true}
              cellWidth={contentWidth}
              copyAnswerFromApplicant={copyAnswerFromApplicant}
              showButtonChangeVisibleExternal={!isExternalQuestion && hasPermission(DISPLAY_SETTING_PERMISSION, V)}
            />
          );
        },
        className: 'h-full',
        onCell: (_data: IQuestionRecord) => {
          return {
            colSpan: 1 + translateDataColumns.length + translateStatusColumns.length + actionColumns.length
          };
        },
        shouldCellUpdate: compareChangeRecord
      },
      ...translateDataColumns,
      ...translateStatusColumns,
      ...actionColumns
    ];

    return columnOptions;
  }, [dataQuestionnaire]);

  const confirmLockQuestionnaire = async () => {
    const timeout = setTimeout(() => {
      backToList(true);
      hideBasePopup();
    }, HOLD_LOCK_CONFIRM_TIME);
    const showPopup = await showBasePopup({
      title: String(t('common:MSG_P_027.title')),
      msg: String(t('common:MSG_P_027.content')),
      type: TYPE_ICON.CONFIRM
    });

    if (showPopup !== TYPE_ICON.CONFIRM) {
      clearTimeout(timeout);
      backToList();
      return;
    } else {
      clearTimeout(timeout);
      lockQuestionnaire({});
    }

    // Remove lock notification
    if (formId === lockConfirm?.objectId) {
      dispatch(setQuestionnaireConfirmLock(null));
    }
  };

  const inVisibleExUserPaths = useRef<string[]>([]);
  const serializeDataQuestion = (questions: IQuestion[], rootPath: string) => {
    const result: IQuestion[] = [...questions].reduce((res: IQuestion[], data: IQuestion, i: number) => {
      const currentPath = `${rootPath}.${i}`;
      if (data?.hasOwnProperty('visibleExternalUser') && data?.type !== CONTENT_TYPE.QUESTION_ONLY) {
        const parentIsInvisible = inVisibleExUserPaths.current.some((path) => {
          const isInclude = currentPath.startsWith(path);
          if (!isInclude) return false;
          const regex = new RegExp(`^${path}`);
          const relativePath = currentPath.replace(regex, '');
          return relativePath.startsWith('.');
        });

        parentIsInvisible && (data._disabled = true);
        data.visibleExternalUser === false && inVisibleExUserPaths.current.push(currentPath);
      }

      data.placeHolder =
        !data.placeHolder && data.type === CONTENT_TYPE.POSTAL_CODE ? t('postal_code:postal_code_placeholder') ?? '' : data.placeHolder;
      if (
        [
          CONTENT_TYPE.RADIO,
          CONTENT_TYPE.TABLE,
          CONTENT_TYPE.TEXT_MULTI,
          CONTENT_TYPE.DATETIMEFROM_TO,
          CONTENT_TYPE.POSTAL_CODE,
          CONTENT_TYPE.DROPDOWN,
          CONTENT_TYPE.MULTI_DROPDOWN,
          CONTENT_TYPE.UNIT_SELECT,
          CONTENT_TYPE.YEAR_MONTH,
          CONTENT_TYPE.YEAR_MONTH_DATE,
          CONTENT_TYPE.CAREER,
          CONTENT_TYPE.EDUCATION,
          CONTENT_TYPE.TRAVEL_HISTORY
        ].includes(data?.type) &&
        data?.answer
      ) {
        let answered = data.answer.answered ? parseJson(data?.answer?.answered ?? '') : data.answer.answered;
        let answeredEn = data.answer.answeredEn ? parseJson(data?.answer?.answeredEn ?? '') : data.answer.answeredEn;
        if ([CONTENT_TYPE.TEXT_MULTI, CONTENT_TYPE.CHECKBOX].includes(data.type)) {
          answered = answered ? (answered as any[])?.map((i) => parseJson(i) ?? i) ?? [] : answered;
          answeredEn = answeredEn ? (answeredEn as any[])?.map((i) => parseJson(i) ?? i) ?? [] : answeredEn;
        }

        if (data?.type === CONTENT_TYPE.MULTI_DROPDOWN) {
          answered = answered && answered.length ? answered.map((i: any) => i.id) : null;
          answeredEn = answeredEn && answeredEn.length ? answered.map((i: any) => i.id) : null;
        }

        data.answer = {
          answered,
          answeredEn,
          isCopyAnswer: !!data.answer.isCopyAnswer
        };
      }

      // Serialize type checkbox
      if (data?.type === CONTENT_TYPE.CHECKBOX && data.answer) {
        data.answer = {
          answered: data.answer.answered ? parseJson(data?.answer?.answered ?? '')?.map((i: any) => i.id) : data.answer.answered,
          answeredEn: data.answer.answeredEn ? parseJson(data?.answer?.answeredEn ?? '').map((i: any) => i.id) : data.answer.answeredEn,
          isCopyAnswer: !!data.answer.isCopyAnswer
        };
      }

      if (data.questions?.length) {
        data.questions = serializeDataQuestion(data.questions, `${currentPath}.questions`);
      }
      if (data.options?.length) {
        data.options = data.options.map((op, j) => {
          if (op.questions?.length) {
            op.questions = serializeDataQuestion(op.questions, `${currentPath}.options.${j}.questions`);
          }
          return op;
        });
      }
      if (!(data?.type === CONTENT_TYPE.QUESTION_ONLY && !data?.questions?.length)) {
        res.push(data);
      }
      return res;
    }, []);
    return result;
  };

  const refreshData = async () => {
    // Refresh question detail with latest version
    setDataSource([]);
    setInitFlg(true);
    isExternalQuestion ? fetchData() : setQuery?.({});
  };

  const translateQuestionnaire = async () => {
    const result = await translate({});
    if (result?.status === HTTP_STATUS_CODE.SUCCESS) {
      refreshData();
    }
  };

  const downloadPdfFile = async () => {
    dispatch(setLoadingPage(true));
    const clonedTable = pdfRenderRef.current;
    const paddingTop = 40;
    Object.assign(clonedTable.style, {
      position: 'fixed',
      left: 0,
      top: 0,
      zIndex: -1, // make pdf not cover the page
      width: 'auto',
      height: 'auto',
      overflow: 'visible',
      display: 'block'
    });
    equalizeTdHeights(clonedTable?.querySelectorAll('.ant-table-row') ?? []);

    // Use html2canvas to capture the cloned table
    html2canvas(clonedTable, { scale: 1 })
      .then((canvas) => {
        if (!dataQuestionnaire) {
          return;
        }
        const tableRows = pdfRenderRef.current.querySelectorAll('.description-row, .ant-table-thead, .ant-table-row'); // Assuming rows are direct children
        const pdf = new jsPDF({
          orientation: 'portrait',
          unit: 'px',
          format: 'a4'
        });

        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = pdf.internal.pageSize.getHeight();
        const originalCanvas = canvas;
        const canvasWidth = originalCanvas.width;
        const ratio = pdfWidth / canvasWidth;
        let currentPageHeight = 0;
        const arr: number[] = [];
        const padding = 47;
        const pageClientHeight = pdfHeight / ratio - padding;
        tableRows.forEach((row: any, index: number) => {
          let h = row.clientHeight;
          if (h > pageClientHeight) {
            arr.push(currentPageHeight);
            currentPageHeight = 0;
            while (h > pageClientHeight) {
              arr.push(pageClientHeight);
              h = h - pageClientHeight;
            }
          }
          if ((currentPageHeight + h + padding) * ratio > pdfHeight) {
            arr.push(currentPageHeight);
            currentPageHeight = 0;
          }

          currentPageHeight += h;
          if (index === tableRows.length - 1) {
            // add more px to show border row in last page
            arr.push(currentPageHeight + 8);
          }
        });

        let renderedHeight = paddingTop;
        arr.forEach((h, index) => {
          // Add a new page if there's more content to add
          if (index !== 0) {
            pdf.addPage();
          }

          // Create a new canvas for the current segment
          const segmentCanvas = document.createElement('canvas');
          segmentCanvas.width = canvasWidth;
          segmentCanvas.height = h;
          const segmentContext = segmentCanvas.getContext('2d');

          if (segmentContext) {
            // Draw the current segment of the original canvas onto the segment canvas
            segmentContext.drawImage(originalCanvas, 0, -renderedHeight);

            // Convert the segment canvas to an image and add it to the PDF
            const imgData = segmentCanvas.toDataURL('image/png');
            pdf.addImage(imgData, 'PNG', 0, 5, pdfWidth, h * ratio);
          }

          // Move to the next segment
          renderedHeight += h;
        });

        const fileName = `${dataQuestionnaire?.projectCode ?? ''}_${dataQuestionnaire.lastName} ${dataQuestionnaire.firstName}_${t('sider:questionnaire')}`;
        pdf.save(`${fileName}.pdf`);
      })
      .finally(() => {
        // Reset the loading state
        // Remove the cloned table from the body
        setDataSourcePDF([]);
        setDownloadPdfFlg(false);
        clonedTable.style.display = 'none';
        dispatch(setLoadingPage(false));
      });
  };

  const exportCsv = async () => {
    // TODO: Call API to export CSV
    const response = await exportCsvQuestionnaire({});
    if (response?.status === HTTP_STATUS_CODE.SUCCESS && response?.data && dataQuestionnaire) {
      const fileName = `${dataQuestionnaire?.projectCode ?? ''}_${dataQuestionnaire.lastName} ${dataQuestionnaire.firstName}_${t('sider:questionnaire')}`;
      downloadSingleFileFromURI(response?.data, fileName, response?.headers['content-type'] ?? 'text/csv');
    }
  };

  const renderExportCsv = () => {
    if (isExternalQuestion) {
      return;
    }
    if (!DISPLAY_BUTTON_BY_VERSION[questionnaireVersion].exportCsv || isExternalLock) {
      return;
    }
    if (!hasPermission(EXPORT_CSV_PERMISSION, V)) {
      return;
    }
    return (
      <BaseButton
        size='medium'
        type='tertiary'
        disabled={loading || loadingExportCsv || isDirty}
        onClick={() => {
          exportCsv();
        }}
        icon={<DownloadIcon className='[&>path]:!fill-none [&>path]:stroke-[currentColor]' />}
      >
        {t('project:question_detail:buttons:export_csv')}
      </BaseButton>
    );
  };

  const renderCancel = () => {
    if (!DISPLAY_BUTTON_BY_VERSION[questionnaireVersion].cancel || !isLatestVersion || isExternalLock) {
      return;
    }
    return (
      <BaseButton disabled={loading || !isDirty} size='medium' type='tertiary' onClick={() => cancelQuestionnaire()}>
        {t('project:question_detail:buttons:cancel')}
      </BaseButton>
    );
  };

  const renderExportPdf = () => {
    if (!DISPLAY_BUTTON_BY_VERSION[questionnaireVersion].exportPdf) {
      return;
    }
    return (
      <BaseButton
        disabled={loading || isDirty}
        size='medium'
        type='tertiary'
        onClick={() => {
          dispatch(setLoadingPage(true));
          setDataSourcePDF(dataSource);
          setDownloadPdfFlg(true);
        }}
        icon={<DownloadIcon className='[&>path]:!fill-none [&>path]:stroke-[currentColor]' />}
      >
        {t('project:question_detail:buttons:export_pdf')}
      </BaseButton>
    );
  };

  const renderTranslate = () => {
    if (isExternalQuestion) {
      return;
    }
    if (!DISPLAY_BUTTON_BY_VERSION[questionnaireVersion].translate || !isLatestVersion || isExternalLock) {
      return;
    }
    if (!hasPermission(TRANSLATE_PERMISSION, V)) {
      return;
    }
    return (
      <BaseButton
        size='medium'
        type='tertiary'
        disabled={loading || loadingTranslate || isDirty}
        onClick={translateQuestionnaire}
        icon={<ArrowClockWiseIcon className='[&>g>path]:!fill-[currentColor]' />}
      >
        {t('project:question_detail:buttons:translate')}
      </BaseButton>
    );
  };

  const renderSave = () => {
    if (!DISPLAY_BUTTON_BY_VERSION[questionnaireVersion].save || !isLatestVersion || isExternalLock) {
      return;
    }
    return (
      <BaseButton disabled={loading || !isDirty} onClick={() => saveQuestionnaire(false)} size='medium' type='secondary'>
        {`${t('project:question_detail:buttons:save')}`}
      </BaseButton>
    );
  };

  const renderSubmit = () => {
    if (!DISPLAY_BUTTON_BY_VERSION[questionnaireVersion].submit || !isLatestVersion || dataQuestionnaire?.lock) {
      return;
    }

    return (
      <BaseButton size='medium' type='primary' disabled={loading || loadingSaveQuestionnaire} onClick={() => saveQuestionnaire(true)}>
        {!isExternalQuestion && isInternalRole
          ? `${t('project:question_detail:buttons:submit_internal')}`
          : `${t('project:question_detail:buttons:submit_external')}`}
      </BaseButton>
    );
  };

  const renderUnlockSubmit = () => {
    if (isExternalQuestion) {
      return;
    }

    if (!DISPLAY_BUTTON_BY_VERSION[questionnaireVersion].unlock || !isLatestVersion) {
      return;
    }

    if (!hasPermission(UNLOCK_SUBMIT_PERMISSION, C)) {
      return;
    }

    if (!dataQuestionnaire?.lock) {
      return;
    }

    return (
      <BaseButton icon={<LockKeyIcon />} size='medium' type='primary' onClick={unlockSubmitQuestionnaire} disabled={loading || loadingUnlockSubmit}>
        {t('project:question_detail:buttons:unlock_submit')}
      </BaseButton>
    );
  };

  return (
    <div>
      <FormProvider {...form}>
        <div className='flex justify-between gap-4'>
          <div className='flex-col min-w-0'>
            <div className='flex gap-x-4'>
              <span className='title-24'>{t('project:question_detail:title')}</span>
              <div className='w-[200px]'>
                {questionnaireVersion !== QUESTION_VERSIONS.NONE ? (
                  <SelectCustom
                    loading={loading}
                    options={dataQuestionnaire?.questionnaireVersions
                      .filter((i) => i !== QUESTION_VERSIONS.NONE)
                      .map((i) => ({
                        label: t(`project:question_detail:versions:${i}`),
                        value: i
                      }))}
                    value={dataQuestionnaire?.questionnaireVersion ?? QUESTION_VERSIONS.ORIGINAL}
                    onChange={(value) => {
                      setQuery?.({ questionnaireVersion: value });
                    }}
                    allowClear={false}
                  />
                ) : (
                  ''
                )}
              </div>
            </div>
            <div className='flex gap-x-10 body-400 mt-2 min-w-0'>
              {dataQuestionnaire && (
                <Tooltip
                  title={`${[dataQuestionnaire.lastName ?? '', dataQuestionnaire.firstName ?? ''].join(' ')} （${dataQuestionnaire.objectName ?? ''}）`}
                >
                  <span className='truncate'>
                    {`${t('project:question_detail:owner_name')}：${[dataQuestionnaire.lastName ?? '', dataQuestionnaire.firstName ?? ''].join(' ')} （${dataQuestionnaire.objectName ?? ''}）`}
                  </span>
                </Tooltip>
              )}
              {questionnaireVersion === QUESTION_VERSIONS.ORIGINAL ? (
                <Tooltip title={dataQuestionnaire?.submittedBy}>
                  <span className='truncate'>
                    {`${t('project:question_detail:submit_by')}：${DataViewer.display(dataQuestionnaire?.submittedBy ?? '')}`}
                  </span>
                </Tooltip>
              ) : (
                ''
              )}
              {questionnaireVersion === QUESTION_VERSIONS.ORIGINAL ? (
                <span className='shrink-0'>
                  {`${t('project:question_detail:submit_at')}：${DataViewer.localTimeBy(dataQuestionnaire?.submittedAt ?? '', FORMAT_DATE_EN_HH_MM_SS)}`}
                </span>
              ) : (
                ''
              )}
              {questionnaireVersion === QUESTION_VERSIONS.UPDATED ? (
                <Tooltip title={dataQuestionnaire?.updatedBy}>
                  <span className='truncate'>
                    {`${t('project:question_detail:updated_by')}：${DataViewer.display(dataQuestionnaire?.updatedBy ?? '')}`}
                  </span>
                </Tooltip>
              ) : (
                ''
              )}
              {questionnaireVersion === QUESTION_VERSIONS.UPDATED ? (
                <span className='shrink-0'>
                  {`${t('project:question_detail:updated_at')}：${DataViewer.localTimeBy(dataQuestionnaire?.updatedAt ?? '', FORMAT_DATE_EN_HH_MM_SS)}`}
                </span>
              ) : (
                ''
              )}
            </div>
          </div>
          <div className='flex items-center justify-end gap-x-4'>
            {isExternalQuestion && (
              <BaseButton type='tertiary' onClick={backToList}>
                {t('button:back')}
              </BaseButton>
            )}
            {renderCancel()}
            {renderExportPdf()}
            {renderExportCsv()}
            {renderTranslate()}
            {renderSave()}
            {renderUnlockSubmit()}
            {renderSubmit()}
          </div>
        </div>
        <div ref={tableRef}>
          <QuestionTable
            columns={columns}
            loading={dataQuestionnaire === null || loading}
            dataSource={dataSource}
            rowKey={(record) => record.id}
            className={isExternalQuestion ? 'external-question-detail' : ''}
          />
        </div>
        <div ref={pdfRenderRef} className='px-[32px] py-[40px]' style={{ display: 'none' }}>
          {dataSourcePDF && !!dataSourcePDF.length && (
            <QuestionPDF
              width={size?.width || 0}
              dataQuestionnaire={dataQuestionnaire}
              questionnaireVersion={questionnaireVersion}
              dataSource={structuredClone(dataSourcePDF) ?? []}
              onRenderSuccessfully={() => {
                if (downloadPdfFlg) {
                  downloadPdfFile();
                }
              }}
            />
          )}
        </div>
        <StickyButtons
          scrollToTop={() => {
            tableRef.current?.querySelector('.ant-table-body table td').scrollIntoView({
              behavior: 'smooth',
              block: 'nearest'
            });
          }}
          moveToNextQuestion={highlightQuestionNotAnswer}
        />
        <ConfirmCancelEditing condition={isDirty} onAccept={handleUnlockQuestionnaire} />
      </FormProvider>
    </div>
  );
};
