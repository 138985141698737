import { DefaultOptionType } from 'antd/es/select';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import AppTooltip from '@/components/app-tooltip/AppTooltip';
import CountryFlag from '@/components/common/country-flag';
import FormItem from '@/components/common/form-item';
import { TagRenderComponent } from '@/components/form-select/FormSelect';
import { ISelectCustomProps, SelectCustom } from '@/components/form-select/SelectCustom';

import useFetch from '@/hooks/useFetch';
import { mappingOptionsCountry } from '@/hooks/useOptionsGlobal';

import { API } from '@/utils/constants/Apis';
import { arrayToDict } from '@/utils/helpers/globalHelper';
import { ICountry } from '@/utils/interfaces/masterdata';

interface IDropdownProps extends ISelectCustomProps {
  name: string;
  endPoint?: string;
  keyLabel?: string;
  keyValue?: string;
  multiSelect?: boolean;
}

const Dropdown: React.FC<IDropdownProps> = ({
  readOnly,
  multiSelect = false,
  placeholder,
  name,
  endPoint,
  keyLabel,
  keyValue,
  options,
  onChange,
  ...props
}) => {
  const form = useFormContext();
  const nameOrigin = name.replace('answeredEn', 'answered');
  const { data, loading } = useFetch(endPoint ?? '', 'GET');
  const isAPICountry = endPoint && (API.GET_MASTER_COUNTRIES.includes(endPoint) || API.GET_MASTER_COUNTRIES_PUBLIC.includes(endPoint));
  const dataOptions: DefaultOptionType[] = !endPoint
    ? options ?? []
    : !isAPICountry
      ? ((data as any[]) ?? []).map((i: any) => {
          return {
            label: i[keyLabel || ''],
            value: i[keyValue || 'id']
          };
        })
      : ((data as any[]) ?? []).map((i: any) => {
          return {
            label: i['countryName'],
            value: i['id']
          };
        });

  const countryDict = isAPICountry ? arrayToDict(mappingOptionsCountry((data ?? []) as ICountry[]), 'id') : {};

  const optionRender = !isAPICountry
    ? undefined
    : (option: DefaultOptionType) => {
        const country = countryDict[option?.value ?? ''];
        return (
          <>
            <div className='flex items-center base-input-select-option'>
              <AppTooltip title={country.label}>
                {country.imgUrl && (
                  <div className='flex items-center'>
                    {!readOnly ? <country.FlagIcon className='mr-[11px]' /> : <CountryFlag countryCode={country.imgUrl} />}
                    <span className='w-full'>{country.label}</span>
                  </div>
                )}
                {!country.imgUrl && <span className='w-full'>{country.label}</span>}
              </AppTooltip>
            </div>
          </>
        );
      };

  useEffect(() => {
    const questionName = (nameOrigin ?? '').replace(/.answer.answered(\.[\d]+)*$/, '').replace(/.answer.answeredEn(\.[\d]+)*$/, '');
    if (endPoint && dataOptions.length) {
      form.setValue(
        `${questionName}.options`,
        dataOptions.map((op) => ({
          id: op.value,
          title: op.label
        }))
      );
    }
  }, [data]);

  return (
    <FormItem name={multiSelect ? `${nameOrigin}` : `${nameOrigin}.id`}>
      <SelectCustom
        id={nameOrigin}
        readOnly={readOnly}
        placeholder={readOnly ? '' : placeholder}
        loading={loading}
        options={endPoint ? dataOptions : options}
        optionRender={optionRender}
        className='w-full h-full'
        allowClear={!readOnly}
        showSearch={!readOnly}
        onChange={(value) => {
          if (!multiSelect) {
            const option = dataOptions.find((i) => i.value === value);
            form.setValue(`${nameOrigin}.title`, option?.label ?? null, {
              shouldValidate: true,
              shouldDirty: true
            });
            option && onChange && onChange(value, option);
            return;
          }
        }}
        allowValueNotInOptions={false}
        mode={multiSelect ? 'multiple' : undefined}
        tagRender={
          !multiSelect
            ? undefined
            : (props) => {
                return (
                  <TagRenderComponent
                    label={props.label}
                    closable={true}
                    showCloseItem={false}
                    tagShowTooltip={false}
                    rest={undefined}
                    tagMouseDown={() => {}}
                    tagClosing={(_e, props) => {
                      form.setValue(
                        name,
                        form.getValues(name).filter((i: any) => i !== props.value),
                        { shouldDirty: true }
                      );
                    }}
                    props={props}
                  />
                );
              }
        }
        {...props}
      />
    </FormItem>
  );
};

export default Dropdown;
