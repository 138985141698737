export enum STATUS_NAME {
  TODO = '開始前',
  IN_PROGRESS = '進行中',
  PENDING = '遅延',
  COMPLETED = '完了'
}

export interface IPrimary {
  countryIds?: string[] | null;
  categoryId?: string[] | null;
  picIds?: string[] | null;
  companyId?: string | null;
  process?: string | null;
  status?: string | null;
  departureFrom?: string | null;
  departureTo?: string | null;
  createDateFrom?: string | null;
  createDateTo?: string | null;
  deadlineFrom?: string | null;
  deadlineTo?: string | null;
  isDraft?: boolean | null;
}

export interface ICategories {
  id: string;
  name: string;
  displayOrder: number;
}

export interface IFormFilter {
  picBy?: string | null;
  primary: IPrimary;
  search?: string | null;
}

export interface IStatus {
  id: string;
  name: STATUS_NAME;
  displayOrder: number;
}

export interface IQueryFilterParams {
  keyword?: string;
  picIds?: string[];
  countryIds?: string[];
  categoryId?: string;
  companyId?: string;
  process?: string;
  status?: string;
  departureDefault?: string;
  departureFrom?: string;
  departureTo?: string;
  createDateFrom?: string;
  createDateTo?: string;
  deadlineFrom?: string;
  deadlineTo?: string;
  isDraft?: string;
}

export const PROCESS_TRAVEL_NAME = '渡航';
