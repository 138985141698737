import { Button, Tag } from 'antd';
import { DefaultOptionType } from 'antd/es/select';
import React from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { TASK_TYPE } from '@/pages/project-management/detail/constant';

import { ITaskFormFilter } from '../../utils';

interface IRenderTagProps {
  form: UseFormReturn<ITaskFormFilter>;
  picOptions: DefaultOptionType[];
  formValues: any;
  totalFiltered: number;
}

const RenderTagTaskList = ({ form, picOptions, formValues, totalFiltered }: IRenderTagProps) => {
  const { t } = useTranslation();

  if (!totalFiltered) return <></>;
  const valueDisplay = (key: string, value: any) => {
    if (key === 'category' && value === TASK_TYPE.OTHER) return t('template_layout:other_category');
    return value;
  };

  return (
    <div className='mt-4 flex flex-wrap'>
      <div className='flex flex-wrap'>
        {Object.keys(formValues).map((key: string) => {
          let value: string | string[] | null | undefined = formValues[key];
          if (!value) return;
          if (key === 'picIds') {
            return (
              <>
                {typeof value === 'object' &&
                  value?.map((pic) => (
                    <Tag
                      key={'pic' + pic}
                      closable
                      className='bg-gray2 body-400 tag-item flex items-center'
                      onClose={() => {
                        const newData = Array.isArray(value) ? value.filter((item: any) => item !== pic) : [];
                        form.reset({
                          ...formValues,
                          picIds: newData.length > 0 ? newData : undefined
                        });
                      }}
                    >
                      {picOptions?.find((i: any) => i.value === pic)?.label}
                    </Tag>
                  ))}
              </>
            );
          }
          if (key === 'category') {
            return (
              <>
                {typeof value === 'object' &&
                  value.map((category: string) => (
                    <Tag
                      key={'category' + category}
                      closable
                      className='bg-gray2 body-400 tag-item flex items-center'
                      onClose={() => {
                        form.reset({
                          ...formValues,
                          category: value.filter((item) => item !== category)
                        });
                      }}
                    >
                      {category === TASK_TYPE.OTHER ? t('template_layout:other_category') : category}
                    </Tag>
                  ))}
              </>
            );
          }
          return (
            <Tag
              key={key}
              closable
              className='bg-gray2 body-400 tag-item flex items-center'
              onClose={() => {
                form.reset({
                  ...formValues,
                  [key]: null
                });
              }}
            >
              {valueDisplay(key, value)}
            </Tag>
          );
        })}
      </div>
      <Button
        size='small'
        className='tag-btn__clear-all font-semibold'
        onClick={() => {
          form.reset({
            category: null,
            status: null,
            deadlineDateFrom: null,
            deadlineDateTo: null,
            picIds: null
          });
        }}
      >
        {t('button:clear_filter')}
      </Button>
    </div>
  );
};

export default RenderTagTaskList;
