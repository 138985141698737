import dayjs, { Dayjs } from 'dayjs';
import tz from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import DOMPurify from 'dompurify';
import * as _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import i18n from '../../i18n';
import {
  CONFIG_DOM_PURIFY,
  FORMAT_DATE_EN,
  FORMAT_DATE_EN_HH_MM_SS,
  FORMAT_DATE_FILTER,
  FORMAT_DATE_TIME_EN,
  LOCALSTORAGE
} from '../constants/AppConstants';
import { IFilterForm, IFilterFormParameters, ISearchFormParameters } from '../interfaces/form';
import { IParams } from '../interfaces/service';

const convertStringToPathName = (value: string) => {
  const pathName = value.split('_');

  return pathName;
};

const formatDateTime = (value: string | Date | Dayjs, format: string = FORMAT_DATE_EN) => {
  const formatD = format ? format : FORMAT_DATE_EN;
  if (dayjs(value).isValid()) {
    return dayjs(value).format(formatD);
  }
  return '';
};

const getEndOfDay = (value: string, format: string = FORMAT_DATE_EN_HH_MM_SS) => {
  const formatD = format || FORMAT_DATE_EN_HH_MM_SS;
  if (dayjs(value).isValid()) {
    return dayjs(value).endOf('day').format(formatD);
  }
  return '';
};

const formatDateRequest = (value: string | Dayjs | null | undefined, format: string = FORMAT_DATE_FILTER) => {
  const formatD = format ? format : FORMAT_DATE_EN;
  if (dayjs(value, formatD).isValid()) {
    return dayjs(value).format(formatD);
  }
  return '';
};

const formatStringToDayjs = (value: string) => {
  if (dayjs(value, FORMAT_DATE_EN).isValid()) {
    return dayjs(value, FORMAT_DATE_EN);
  }
  return '';
};

const sinceDateBefore = (days: number) => {
  return dayjs().subtract(days, 'day').startOf('day').format(FORMAT_DATE_EN);
};

const checkValidDate = (value?: string | Dayjs | null | undefined, format: string = FORMAT_DATE_EN) => {
  if (!value) return false;
  if (dayjs(value, format, true).isValid()) {
    return true;
  }
  return false;
};

const formatDayjsToString = (value: Dayjs | string) => {
  return dayjs(value).format(FORMAT_DATE_EN) !== 'Invalid Date' ? dayjs(value).format(FORMAT_DATE_EN) : '';
};

const convertDataQuestionProject = (dataQuestion: any[]) => {
  const addValue = {
    value: '',
    valueOther: '',
    endDate: null,
    startDate: null,
    fileList: ''
  };

  const handleFormatQuestion = (dataFormat: any[]) => {
    if (!dataFormat.length) return [];
    const newData = dataFormat.map((item: any) => {
      const tmp = {
        ...addValue,
        ...item
      };

      if (tmp.options.length > 0) {
        const newOptions = tmp.options.map((item: any) => {
          if (item.questions.length > 0) {
            return {
              ...item,
              questions: handleFormatQuestion(item.questions)
            };
          }
          return item;
        });
        return {
          ...tmp,
          options: newOptions
        };
      }
      return tmp;
    });
    return newData;
  };

  return handleFormatQuestion(dataQuestion);
};
const _handleQueryType = (field: string): string => {
  let type = 'text';
  if (field === 'dateFrom' || field === 'dateTo' || field === 'createdUtc') {
    type = 'date';
  }
  return type;
};
const _handleOperation = (field: string): string => {
  let operation = '';
  switch (field) {
    case 'countryId':
    case 'projectId':
    case 'categoryId':
    case 'formId':
    case 'code':
    case 'createdUtc':
    case 'patternId':
      operation = 'eq';
      break;
    case 'dateFrom':
      operation = 'gte';
      break;
    case 'dateTo':
      operation = 'lte';
      break;
    case 'name':
      operation = 'sw';
      break;
    default:
      operation = 'contains';
      break;
  }
  return operation;
};
const filterHelper = (values: any) => {
  const filters = Object.keys(values).map((v: any) => {
    return {
      fieldTitle: v,
      queryValue: values[v]
    };
  });
  let newFilter: any = [];
  const availableFilters = filters.filter((f: any) => f.queryValue);

  for (let index = 0; index < availableFilters.length; index++) {
    const { fieldTitle, queryValue } = availableFilters[index];
    if (queryValue) {
      newFilter = [
        ...newFilter,
        {
          fieldTitle: fieldTitle === 'dateFrom' || fieldTitle === 'dateTo' ? 'updatedUtc' : fieldTitle,
          queryType: _handleQueryType(fieldTitle),
          operation: _handleOperation(fieldTitle),
          queryValue: checkValidDate(queryValue) ? dayjs(queryValue).format('YYYY-MM-DDTHH:mm:ss:SSS') : queryValue,
          queryCondition: index > 0 ? 'and' : ''
        }
      ];
    }
  }
  return newFilter.length ? JSON.stringify(newFilter) : '';
};

const filterQuery = (values: any, dateFieldName: string = 'updatedUtc'): string => {
  const filters = Object.keys(values).map((v: any, i: number) => {
    return {
      fieldTitle: v === 'dateFrom' || v === 'dateTo' ? dateFieldName : v,
      queryType: values[v].type,
      queryValue:
        values[v].value && (values[v].type === 'date' || values[v].type === 'nullable_date')
          ? dayjs(values[v].value as string).format(FORMAT_DATE_FILTER)
          : values[v]?.value,
      operation: values[v].operation,
      queryCondition: i > 0 ? 'and' : ''
    };
  });
  const availableFilters = filters.filter((f: any) => f.queryValue);
  const query = availableFilters.length > 0 ? JSON.stringify(availableFilters) : '';
  return query;
};

const _generateFilterQuery = (values: IFilterForm, condition: string = 'and'): any => {
  const filters = Object.keys(values).map((v: string, i: number) => {
    let queryValue: string = '';
    // debugger
    if (Array.isArray(values[v].value)) {
      const filterValues = (values[v]?.value as string[]) || [];
      queryValue = filterValues?.join(';');
      queryValue = queryValue.replace(/null/g, '');
    } else {
      if (values[v].value && (values[v].type === 'date' || values[v].type === 'nullable_date')) {
        queryValue = dayjs(values[v].value as string).format(FORMAT_DATE_FILTER);
      } else {
        queryValue = (values[v]?.value as string) || '';
      }
    }
    if (values[v].operation === 'gte' || values[v].operation === 'lte') {
      if (values[v].operation === 'gte') {
        const date = queryValue ? dayjs(queryValue).hour(0).minute(1) : null;
        queryValue = date ? dayjs(date).format(FORMAT_DATE_FILTER) : '';
      } else {
        const date = queryValue ? dayjs(queryValue).hour(23).minute(59) : null;
        queryValue = date ? dayjs(date).format(FORMAT_DATE_FILTER) : '';
      }
    }
    return {
      fieldTitle: values[v].fieldTitle ? values[v].fieldTitle : v,
      queryType: values[v].type,
      queryValue,
      operation: values[v].operation,
      queryCondition: i > 0 ? condition : ''
    };
  });
  return filters;
};

const _generateFilterSearchQuery = (fields: string[], keyword: string) => {
  const querySearch: any = {
    queryCondition: '',
    childrens: fields.map((field: string, i: number) => {
      return {
        fieldTitle: `${field}.ToLower()`,
        queryType: 'text',
        queryValue: convertFullWidthToHalfWidth(keyword.toLocaleLowerCase()),
        operation: 'contains',
        queryCondition: i > 0 ? 'or' : ''
      };
    })
  };
  return querySearch;
};

const _generateQueryCheckDuplicate = (fields: ISearchFormParameters[]) => {
  const querySearch: any = fields.map((field: ISearchFormParameters, i: number) => {
    return {
      fieldTitle: field.fieldTitle,
      queryType: field.queryType,
      queryValue: field.queryValue,
      operation: field.operation,
      queryCondition: i > 0 ? 'and' : ''
    };
  });
  return JSON.stringify([...querySearch]);
};

const _generateQuery = (filter: { queryFilterSearch: any; queryFilter: IFilterFormParameters[] }) => {
  const { queryFilter, queryFilterSearch } = filter;
  const availableFilters = queryFilter?.filter((q: IFilterFormParameters) => q.fieldTitle && q.operation && q.queryType);
  let query: string = '';
  if (availableFilters && queryFilterSearch) {
    const queryArr = [
      ...availableFilters,
      {
        ...queryFilterSearch,
        queryCondition: 'and'
      }
    ];
    query = JSON.stringify(queryArr);
  } else if (availableFilters && !queryFilterSearch) {
    query = JSON.stringify(availableFilters);
  } else if (!availableFilters && queryFilterSearch) {
    query = JSON.stringify([queryFilterSearch]);
  }
  return query;
};

const slugify = (text: string, separator: string = '-') => {
  return text
    .toLowerCase()
    .replace(/[^\w ]+/g, '')
    .replace(/ +/g, separator);
};

const ucFirst = (str: string): string => {
  const result = str.charAt(0).toUpperCase() + str.slice(1);
  return result;
};

const handleNotOnlyEnteringSpaces = (text: string) => {
  const isOnlyWhitespace: boolean = /^\s*$/.test(text);
  const newValue: string = isOnlyWhitespace ? '' : text;
  return newValue;
};

const stripHtml = (html: string): string => {
  let tmp = document.createElement('DIV');
  tmp.innerHTML = DOMPurify.sanitize(html);
  return tmp.textContent || tmp.innerText || '';
};

const handleTableHeight = (height: number) => {
  let countHeight = height;
  switch (countHeight) {
    case 157:
      countHeight += 90;
      break;
    case 100:
      countHeight += 88;
      break;
    case 40:
      countHeight += 89;
      break;
    case 50:
      countHeight += 79;
      break;
    case 0:
      countHeight += 133;
      break;
  }
  return countHeight;
};
const convertDateTimeToLocalZone = (date: string, format: string = FORMAT_DATE_TIME_EN): string => {
  dayjs.extend(utc);
  dayjs.extend(tz);
  const timeZone = dayjs.tz.guess();

  return dayjs.utc(date).tz(timeZone).format(format);
};

const renderSpecialText = (value: string): any => {
  let result: string = value;
  if (value.includes('  ') || value.includes(' ')) {
    result = value.replace(/\s/g, '\u00A0');
  }
  return result.replace(/\r?\n|\r/g, '');
};

const cleanLocalCache = () => {
  for (let index = 0; index < Object.keys(localStorage).length; index++) {
    const name = Object.keys(localStorage)[index];
    if (name !== LOCALSTORAGE.FILTERED_VALUES && name !== LOCALSTORAGE.FILTER_QUERY_STRING && name !== LOCALSTORAGE.FILTER_ANNOUNCEMENT) {
      localStorage.removeItem(name);
    }
  }
};

const generalQuestionData = (id: string, dropdownId: string) => {
  const optionId = uuidv4();
  return {
    id,
    name: '',
    isOnlyPdf: false,
    maxNumFile: 1,
    maxFileSize: 10,
    tooltip: '',
    typeId: dropdownId,
    options: [{ name: i18n.t('questionnaire:option', { number: 1 }), hasQuestion: false, questions: [], id: optionId, hasNew: true, checked: false }],
    value: '',
    fileList: '',
    endDate: null,
    startDate: null,
    valueOther: '',
    hasNew: true
  };
};

const createNameTab = (name: string, items: string[]) => {
  const matchingItems = items.filter((item) => item.includes(name)).sort();

  if (matchingItems.length === 0) {
    return name;
  }

  if (!matchingItems.includes(name)) {
    return name;
  }

  let newName = name;
  let index = 0;

  while (matchingItems.includes(newName)) {
    index++;
    newName = `${name}(${index})`;
  }

  return newName;
};

const handleSorter = (sorter: string, field: string) => {
  if (sorter.includes(field)) {
    return sorter.includes('asc') ? 'ascend' : 'descend';
  }
  return undefined;
};

const insertAtIndex = (str: string, substring: string, index: number) => {
  return str.slice(0, index) + substring + str.slice(index);
};

const emptyObject = (obj: object) => Object.keys(obj).length === 0;

const resetDataQuestion = (questions: any) => {
  if (questions.length === 0) {
    return [];
  }

  return questions.map((question: any) => {
    const singleLine = question.singleLine ? question.singleLine.map(() => ({ value: '' })) : question.singleLine;
    return {
      ...question,
      options: question.options.length ? resetDataChild(question.options) : [],
      value: '',
      singleLine,
      valueOther: '',
      endDate: null,
      startDate: null,
      fileList: ''
    };
  });
};

const resetDataChild = (options: any) => {
  if (options.length === 0) {
    return [];
  }

  return options.map((option: any) => ({
    ...option,
    questions: option.questions ? resetDataQuestion(option.questions) : []
  }));
};

const decodeFileName = (fileName: string) => {
  try {
    return decodeURI(fileName);
  } catch {
    return fileName;
  }
};

const _uuid = uuidv4();

const _guuid = () => {
  return uuidv4();
};

const getPageIndexWhenDeleted = ({
  deleted,
  params,
  totalPage,
  total
}: {
  deleted: number;
  params: IParams;
  totalPage: number;
  total: number;
}): number => {
  let pageIndex: number = 0;

  if (params) {
    const isLastPage = params.pageIndex === totalPage - 1;
    pageIndex = params.pageIndex;
    if (isLastPage) {
      pageIndex = Math.ceil((total - deleted) / params.pageSize) - 1;
    }
  }

  if (pageIndex === -1) return 0;

  return pageIndex;
};

const getCookie = (cname: string): string => {
  const name = cname + '=';
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');

  for (const cookie of ca) {
    let c = cookie.trim();

    if (c.startsWith(name)) {
      return c.substring(name.length);
    }
  }
  return '';
};

const setCookie = (name: string, value: string, expirationDays: number) => {
  const expirationDate = new Date();
  expirationDate.setDate(expirationDate.getDate() + expirationDays);
  document.cookie = `${name}=${value}; expires=${expirationDate.toUTCString()}; path=/`;
};

const convertFullWidthToHalfWidth = (input: string) => {
  const fullWidth =
    `、。・「」！＂＃＄％＆＇（）＊＋，－．／：；＜＝＞？＠［＼］＾＿｀｛｜｝～｟｠￠￡￢￣￤￥￦│←↑→↓■○` +
    `アイウエオカキクケコサシスセソタチツテトナニヌネノハヒフヘホマミムメモヤユヨラリルレロワヲンァィゥェォッャュョヮヰヱヵヶーあいうえおかきくけこさしすせそたちつてとなにぬねのはひふへほまみむめもやゆよらりるれろわをんぁぃぅぇぉっゃゅょゎゐゑヵヶー０１２３４５６７８９ＡＢＣＤＥＦＧＨＩＪＫＬＭＮＯＰＱＲＳＴＵＶＷＸＹＺａｂｃｄｅｆｇｈｉｊｋｌｍｎｏｐｑｒｓｔｕｖｗｘｙｚ` +
    // tslint:disable-next-line:no-irregular-whitespace
    `　`;
  const halfWidth =
    '､｡･｢｣!"#$%&\'()*+,-./:;<=>?@[\\]^_`{|}~⦅⦆¢£¬¯¦¥₩￨￩￪￫￬￭￮ｱｲｳｴｵｶｷｸｹｺｻｼｽｾｿﾀﾁﾂﾃﾄﾅﾆﾇﾈﾉﾊﾋﾌﾍﾎﾏﾐﾑﾒﾓﾔﾕﾖﾗﾘﾙﾚﾛﾜｦﾝｧｨｩｪｫｯｬｭｮﾜｲｴｶｹｰｱｲｳｴｵｶｷｸｹｺｻｼｽｾｿﾀﾁﾂﾃﾄﾅﾆﾇﾈﾉﾊﾋﾌﾍﾎﾏﾐﾑﾒﾓﾔﾕﾖﾗﾘﾙﾚﾛﾜｦﾝｧｨｩｪｫｯｬｭｮﾜｲｴｶｹｰ0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz ';

  const newFullWidthChars = 'ガギグゲゴザジズゼゾダヂヅデドバビブベボパピプペポヴがぎぐげござじずぜぞだぢづでどばびぶべぼぱぴぷぺぽゔ';
  const newHalfWidthChars = 'ｶﾞｷﾞｸﾞｹﾞｺﾞｻﾞｼﾞｽﾞｾﾞｿﾞﾀﾞﾁﾞﾂﾞﾃﾞﾄﾞﾊﾞﾋﾞﾌﾞﾍﾞﾎﾞﾊﾟﾋﾟﾌﾟﾍﾟﾎﾟｳﾞｶﾞｷﾞｸﾞｹﾞｺﾞｻﾞｼﾞｽﾞｾﾞｿﾞﾀﾞﾁﾞﾂﾞﾃﾞﾄﾞﾊﾞﾋﾞﾌﾞﾍﾞﾎﾞﾊﾟﾋﾟﾌﾟﾍﾟﾎﾟｳﾞ';

  let result = '';
  for (let i = 0; i < input.length; i++) {
    const char = input[i];
    const index = fullWidth.indexOf(char);
    if (index !== -1) {
      result += halfWidth[index];
    } else {
      const newIndex = newFullWidthChars.indexOf(char);
      if (newIndex !== -1) {
        result += newHalfWidthChars.substring(newIndex * 2, newIndex * 2 + 2);
      } else {
        result += char;
      }
    }
  }
  return result;
};

const handleLinkClick = (event: any) => {
  if (event.target.tagName === 'A') {
    window.open(event.target.href, '_blank');
    event.preventDefault();
  }
};

const isSecureLink = (element: Element): boolean => {
  if (element.tagName.toLowerCase() === 'a') {
    const href = element.getAttribute('href');
    return !!href && (href.startsWith('http://') || href.startsWith('https://'));
  }
  return false;
};

const sanitizeAndRemoveInsecureLinks = (htmlText: string): string => {
  if (!htmlText) {
    return '';
  }
  const doc = new DOMParser().parseFromString(htmlText, 'text/html');
  const links = doc.querySelectorAll('a');

  links.forEach((link) => {
    if (!isSecureLink(link)) {
      link.outerHTML = '';
    }
  });
  const cleanedHtml = DOMPurify.sanitize(doc.body.innerHTML, CONFIG_DOM_PURIFY);
  return cleanedHtml;
};

const scrollIntoView = (className: string) => {
  setTimeout(() => {
    const element = document.getElementsByClassName(className)[0];
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }, 1000);
};

const intersectArrays = <T, K extends keyof T>(array1: T[], array2: T[], key: K): T[] => {
  const set = new Set(array1.map((item) => item[key]));
  return array2.filter((item) => set.has(item[key]));
};

const arrayToDict = <T, K extends keyof T>(array: T[], key: K): Record<string, T> => {
  return array.reduce((acc: Record<string, T>, item) => {
    acc[item[key] as unknown as string] = item;
    return acc;
  }, {});
};

const groupByKey = <T, K extends keyof T>(array: T[], key: K, sort?: (first: T, second: T) => number): Record<string, T[]> => {
  let returnArr = [...array];
  if (sort) {
    returnArr.sort(sort);
  }
  return returnArr.reduce((acc: Record<string, T[]>, item) => {
    if (!acc[item[key] as unknown as string]) {
      acc[item[key] as unknown as string] = [item];
    } else {
      acc[item[key] as unknown as string].push(item);
    }
    return acc;
  }, {});
};

const getMaxByKey = <T, K extends keyof T>(array: T[], key: K): T | null => {
  return array.reduce((maxItem: T | null, item) => {
    if (_.isNil(item[key])) return maxItem;

    if (!maxItem || maxItem[key] <= item[key]) {
      return item;
    }
    return maxItem;
  }, null);
};

const getMinByKey = <T, K extends keyof T>(array: T[], key: K): T | null => {
  return array.reduce((minItem: T | null, item) => {
    if (_.isNil(item[key])) return minItem;

    if (!minItem || minItem[key] >= item[key]) {
      return item;
    }
    return minItem;
  }, null);
};

const createMatrix = <T>(array: T[], cols: number): T[][] => {
  const matrix: T[][] = [];
  let indexRow = 0;
  array.forEach((item, index) => {
    if (index % cols === 0) {
      indexRow++;
      matrix.push([]);
    }
    matrix[indexRow - 1].push(item);
  });
  return matrix;
};

const replaceStringWithPattern = (str: string, obj: Record<string, string>) => {
  return Object.keys(obj).reduce((newStr, key) => {
    return newStr.replaceAll(`{${key}}`, obj[key]);
  }, str);
};

const parseJson = (json: string) => {
  try {
    return JSON.parse(json);
  } catch (e) {
    return null;
  }
};

const isEmptyField = (field: any): boolean => {
  if (field instanceof Array) {
    return !field.length;
  }
  if (field && typeof field === 'object') {
    return !Object.keys(field).some((k) => field[k]);
  }
  return !field;
};

const findIndexByKey = (arr: any[], id: string) => {
  return arr.findIndex((record) => record.key === id);
};

const arraySwapByIndex = (arr: any[], activeIndex: number, overIndex: number) => {
  const newList = structuredClone(arr);
  const temp = newList[activeIndex];
  newList[activeIndex] = newList[overIndex];
  newList[overIndex] = temp;
  return newList;
};

export {
  _uuid,
  arraySwapByIndex,
  findIndexByKey,
  convertStringToPathName,
  formatDateTime,
  getEndOfDay,
  formatDateRequest,
  formatStringToDayjs,
  formatDayjsToString,
  convertDataQuestionProject,
  checkValidDate,
  filterHelper,
  slugify,
  ucFirst,
  handleNotOnlyEnteringSpaces,
  stripHtml,
  handleTableHeight,
  convertDateTimeToLocalZone,
  filterQuery,
  renderSpecialText,
  cleanLocalCache,
  generalQuestionData,
  createNameTab,
  handleSorter,
  _generateFilterQuery,
  _generateFilterSearchQuery,
  _generateQuery,
  _generateQueryCheckDuplicate,
  insertAtIndex,
  emptyObject,
  resetDataChild,
  decodeFileName,
  _guuid,
  getPageIndexWhenDeleted,
  getCookie,
  setCookie,
  convertFullWidthToHalfWidth,
  handleLinkClick,
  sanitizeAndRemoveInsecureLinks,
  scrollIntoView,
  intersectArrays,
  arrayToDict,
  groupByKey,
  getMaxByKey,
  getMinByKey,
  createMatrix,
  replaceStringWithPattern,
  sinceDateBefore,
  parseJson,
  isEmptyField
};
