import { useFormContext } from 'react-hook-form';

import BaseRadioGroups, { IBaseRadioProps } from '@/components/common/base-radio';
import FormItem from '@/components/common/form-item';

interface IRadioProps extends IBaseRadioProps {
  name: string;
  optionLabelName?: string;
  optionContentName?: string;
}

const Radio: React.FC<IRadioProps> = ({ name, optionLabelName, optionContentName, onChange, ...props }) => {
  const form = useFormContext();
  const nameOrigin = name.replace('answeredEn', 'answered');

  if (nameOrigin && form) {
    return (
      <FormItem name={nameOrigin}>
        <BaseRadioGroups
          className='w-full h-full'
          onChange={(_value, option) => {
            optionLabelName && form.setValue(optionLabelName, option?.label);
            optionContentName && form.setValue(optionContentName, option?.labelInputConfig?.value);
          }}
          {...props}
        />
      </FormItem>
    );
  }
  return <BaseRadioGroups className='w-full h-full' {...props} />;
};

export default Radio;
