import { yupResolver } from '@hookform/resolvers/yup';
import { Space, Tag } from 'antd';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { BaseButton } from '@/components/base-button/BaseButton';
import { TYPE_ICON, showBasePopup } from '@/components/base-popup/BasePopup';

import { IAlertNotificationState, setAlertNotification } from '@/redux/globalReducer';

import useEventListener from '@/hooks/useEventListener';

import { WINDOW_EVENTS } from '@/utils/constants';
import { TYPE } from '@/utils/constants/AppConstants';
import { handleCommonError } from '@/utils/helpers/common';
import { renderProjectCode } from '@/utils/helpers/project';
import { addProjectMemo, getDetailProjectMemo, getProjectInfo, updateProjectMemo } from '@/utils/services/ProjectApiService';

import { IProjectInfo } from '../add-edit/models';
import { ProjectMemoForm } from './components/ProjectMemoForm';
import { DEFAULT_DETAIL_PROJECT_MEMO, DEFAULT_KEY_JOIN_VISA_CATEGORY } from './constants';
import { IProjectMemoRes } from './model';
import { FormProjectMemo } from './validation/FormValidation';

const ProjectMemo = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const dispatch = useDispatch();
  const [projectInfo, setProjectInfo] = useState<IProjectInfo>();
  const [projectMemo, setProjectMemo] = useState<IProjectMemoRes>(DEFAULT_DETAIL_PROJECT_MEMO);

  useEffect(() => {
    hanldeGetProjectInfo();
    handleGetDetailProjectMemo();
  }, [id]);

  useEventListener(WINDOW_EVENTS.BEFORE_UNLOAD, (e) => {
    if (isDirty && !isSubmitting) {
      e.preventDefault();
      e.focus();
    }
  });

  const methods = useForm<IProjectMemoRes>({
    mode: 'onChange',
    defaultValues: DEFAULT_DETAIL_PROJECT_MEMO,
    resolver: yupResolver(FormProjectMemo)
  });

  const { isDirty, isSubmitting } = methods.formState;

  const hanldeGetProjectInfo = async () => {
    if (!id) return;
    try {
      const { data } = await getProjectInfo(String(id));
      setProjectInfo(data);
    } catch (e) {}
  };

  const handleGetDetailProjectMemo = async () => {
    if (!id) return;
    try {
      const { data } = await getDetailProjectMemo(String(id));
      if (data?.id) {
        let visaCategoryId = [];
        if (data?.visaCategoryName) {
          visaCategoryId = data?.visaCategoryName.split(DEFAULT_KEY_JOIN_VISA_CATEGORY) ?? [];
        }
        const projectMemoData = {
          ...data,
          visaCategoryId
        };
        setProjectMemo(projectMemoData);
        methods.reset(projectMemoData);
      }
    } catch (e: any) {
      if (e?.response?.status === 404) {
        setProjectMemo(DEFAULT_DETAIL_PROJECT_MEMO);
      }
    }
  };

  const handleAddProjectMemo = async (value: IProjectMemoRes) => {
    if (!id) return;
    try {
      const { id: memoId, projectId, visaCategoryId, ...rest } = value;
      const dataReq = {
        ...rest,
        visaCategoryName: visaCategoryId.join(DEFAULT_KEY_JOIN_VISA_CATEGORY)
      };
      const { data } = await addProjectMemo(id, dataReq);
      if (data) {
        handleGetDetailProjectMemo();
        dispatch(
          setAlertNotification({
            show: true,
            type: TYPE.SUCCESS,
            message: t('common:MSG_C_003', {
              item: t('button:keep')
            })
          })
        );
      }
    } catch (e: any) {
      const callback = (mess: IAlertNotificationState) => dispatch(setAlertNotification(mess));
      handleCommonError(e, '', callback);
    }
  };

  const handleUpdateProjectMemo = async (value: IProjectMemoRes) => {
    if (!id) return;
    try {
      const { id: memoId, projectId, visaCategoryId, ...rest } = value;
      const dataReq = {
        ...rest,
        visaCategoryName: visaCategoryId.join(DEFAULT_KEY_JOIN_VISA_CATEGORY)
      };
      const { data } = await updateProjectMemo(id, dataReq);
      if (data) {
        handleGetDetailProjectMemo();
        dispatch(
          setAlertNotification({
            show: true,
            type: TYPE.SUCCESS,
            message: t('common:MSG_C_003', {
              item: t('button:keep')
            })
          })
        );
      }
    } catch (e: any) {
      const callback = (mess: IAlertNotificationState) => dispatch(setAlertNotification(mess));
      handleCommonError(e, '', callback);
    }
  };

  const onCancel = async () => {
    const showPopup = await showBasePopup({
      title: String(t('dialog:cancel_editing:header')),
      msg: String(t('dialog:cancel_editing:content')),
      type: TYPE_ICON.CONFIRM_CANCEL_EDIT
    });
    if (showPopup !== TYPE_ICON.CONFIRM) return;
    methods.reset(projectMemo);
  };

  const onSubmit = async (value: IProjectMemoRes) => {
    try {
      if (!projectMemo?.id) await handleAddProjectMemo(value);
      else await handleUpdateProjectMemo(value);
    } catch (e) {}
  };

  if (!id) return <></>;

  const title = renderProjectCode(projectInfo, t('project_memo:project_id', { id: projectInfo?.code }));

  return (
    <div id='project-of-memo' className='p-[20px] bg-[#f5f5f5] h-screen'>
      <div className='h-[calc(100vh-48px)] overflow-y-auto'>
        <FormProvider {...methods}>
          <div className='header flex justify-between mb-[12px]'>
            <div>
              <p className='title-24 whitespace-pre-line'>{t('project_memo:title')}</p>
              <p className='body-400 text-textGray'>{title}</p>
            </div>
            <Space size={16} align='start'>
              <div className='min-w-[120px]'>
                <BaseButton block size='medium' type='tertiary' disabled={!isDirty || isSubmitting} onClick={onCancel}>
                  {t('button:cancel')}
                </BaseButton>
              </div>
              <div className='min-w-[120px]'>
                <BaseButton block size='medium' disabled={(!isDirty || isSubmitting) && !!projectMemo?.id} onClick={methods.handleSubmit(onSubmit)}>
                  <span> {t('button:keep')} </span>
                </BaseButton>
              </div>
            </Space>
          </div>
          <div className='p-[24px] rounded-[10px] bg-white border border-gray2 flex flex-col gap-[12px]'>
            <div className='flex items-center h-[32px] gap-[8px]'>
              <p className='body-400 w-[180px] shrink-0'>{t('project_memo:corporate_name')}</p>
              <p className='body-700 truncate'>{projectInfo?.projectApplicant?.currentCompany ?? ''}</p>
            </div>
            <div className='flex items-center gap-[8px]'>
              <p className='body-400 w-[180px] shrink-0 h-[32px] leading-[32px]'>{t('project_memo:applicant_name')}</p>
              <p className='flex flex-wrap gap-[8px] overflow-hidden w-full'>
                {
                  <Tag className='mr-0 h-[24px] flex items-center px-[8px] rounded-[4px] bg-gray2 max-w-[45%]'>
                    <span className='body-400 truncate'>{`${projectInfo?.projectApplicant?.lastName} ${projectInfo?.projectApplicant?.firstName}`}</span>
                  </Tag>
                }
                {projectInfo?.goWithFamily &&
                  projectInfo?.projectFamilies?.map((el, i) =>
                    el?.lastName || el?.firstName ? (
                      <Tag
                        key={`applicantName-${el?.id}-${i}`}
                        className='mr-0 h-[24px] flex items-center px-[8px] rounded-[4px] bg-gray2 max-w-[45%]'
                      >
                        <span className='body-400 truncate'>{`${el?.lastName} ${el?.firstName}`}</span>
                      </Tag>
                    ) : (
                      <></>
                    )
                  )}
              </p>
            </div>
            <div className='flex items-center h-[32px] gap-[8px]'>
              <p className='body-400 w-[180px] shrink-0'>{t('project_memo:country')}</p>
              <p className='body-700'>{projectInfo?.projectAssignmentInfo?.countryName ?? ''}</p>
            </div>
            <ProjectMemoForm countryId={projectInfo?.projectAssignmentInfo?.countryId ?? ''} />
          </div>
        </FormProvider>
      </div>
    </div>
  );
};

export default ProjectMemo;
