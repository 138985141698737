import { useAppSelector } from '@/hooks';
import { notification } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { fetchReadNotice, setOpenNotice, setPopupNotice, setRefreshPage } from '@/redux/notice/noticeReducer';
import { AppDispatch } from '@/redux/store';

import useAuthorization from '@/hooks/useAuthorization';

import { parseJson } from '@/utils/helpers/globalHelper';

import BellRinging from '@/assets/icons/notification/BellRinging.svg';

import AppTooltip from '../app-tooltip/AppTooltip';

const PopupNotice = () => {
  const { isInternalRole } = useAuthorization();
  const { show, url, dataNotice, title, projectCode, projectCompany, projectAddress } = useAppSelector((state) => state.notice.popupNotice);
  const subMessage = [projectCode, projectCompany, projectAddress].filter((i) => i).join(' ');
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const [isTabActive, setIsTabActive] = useState(true);
  const onOffAlert = useAppSelector((state) => state.notice.onOffAlert);

  const returnNavigate = () => {
    const urlNavigate = String(url);
    // Go to page send notice
    if (urlNavigate && urlNavigate !== window.location.pathname) {
      navigate(urlNavigate);
    } else {
      // Set state show loading refresh page is true
      dispatch(setRefreshPage(true));

      // Set state show loading refresh page is false
      setTimeout(() => {
        dispatch(setRefreshPage(false));
      }, 0);
    }
  };

  const openPageCreateNotice = async () => {
    // Check and return page navigate
    returnNavigate();

    // Off drawer form notice
    dispatch(setOpenNotice(false));

    // Update data change type readed is true
    dispatch(fetchReadNotice({ notice: dataNotice, isReadAlert: true, isInternal: isInternalRole }));
  };

  const close = () => {
    dispatch(
      setPopupNotice({
        show: false
      })
    );
  };

  const renderMessage = () => {
    return (
      <AppTooltip className='form-title' title={title}>
        <div className='form-message body-700'>{title}</div>
      </AppTooltip>
    );
  };

  const renderDescription = () => {
    return (
      <div className='form-description body-small-bold'>
        <AppTooltip className='form-title d-flex' title={subMessage}>
          {projectCode && (
            <div className='max-w-[95px]'>
              <span className='pr-3 block truncate'>{projectCode}</span>
            </div>
          )}
          {projectCompany && (
            <div className='max-w-[95px]'>
              <span className='pr-3 block truncate'>{projectCompany}</span>
            </div>
          )}
          {projectAddress && (
            <div className='max-w-[95px]'>
              <span className='block truncate'>{projectAddress}</span>
            </div>
          )}
        </AppTooltip>
      </div>
    );
  };

  const renderIcon = () => {
    return (
      <div className='!w-[52px] !mr-[12px] common-block-center mb-4'>
        <div className='form-icon w-[38px] h-[38px] common-block-center items-center'>
          <BellRinging />
        </div>
      </div>
    );
  };

  useEffect(() => {
    // Display request for permission to display notification
    if (Notification.permission === 'default') {
      Notification.requestPermission();
    }

    // Handle visibilitychange event to check tab state
    const handleVisibilityChange = () => {
      const isActive = !document.hidden;
      setIsTabActive(isActive);
      localStorage.setItem('isTabActive', String(isActive));
    };
    document.addEventListener('visibilitychange', handleVisibilityChange);

    // Handle event when another tab changes state
    const handleStorageChange = () => {
      setIsTabActive(localStorage.getItem('isTabActive') === 'true');
    };
    window.addEventListener('storage', handleStorageChange);

    // Cleanup event when component unmounts
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  const renderNoticeForOperatingSystem = () => {
    // Check if the application is showing
    if (document.visibilityState === 'hidden') {
      // Create important notifications that are displayed on the operating system
      const noticeKey = `${dataNotice?.id ?? ''}_${dataNotice?.createdDate}`;

      // Get data localStorage of last notice
      let pushedNotices = parseJson(localStorage.getItem('pushedNotices') ?? '');

      if (!pushedNotices) {
        pushedNotices = {};
        localStorage.setItem('pushedNotices', '{}');
      }
      if (pushedNotices[noticeKey]) return;

      // Check if no other tabs are active
      const allTabsInactive = localStorage.getItem('isTabActive') === 'false';

      // Check if the application has permission to send notices to the operating system
      if (Notification.permission === 'granted' && !isTabActive && allTabsInactive) {
        // Initialize content and message for notice
        const notification = new Notification(title ?? '', {
          body: subMessage,
          icon: '../../../../src/assets/icons/notification/System.svg'
        });
        notification.onclick = () => {
          window.focus();
          returnNavigate();
          notification.close();
        };

        // Create data localStorage of notices
        pushedNotices[noticeKey] = 1;
        localStorage.setItem('pushedNotices', JSON.stringify(pushedNotices));
      }
    }
  };

  const openNotice = () => {
    renderNoticeForOperatingSystem();
    const key = dataNotice?.createdDate;
    notification.open({
      key,
      onClick: () => {
        openPageCreateNotice();
        notification.destroy(key);
      },
      onClose: close,
      message: renderMessage(),
      description: renderDescription(),
      icon: renderIcon(),
      placement: 'bottomRight',
      className: 'form-notice cursor-pointer !w-[400px] !p-[20px]'
    });
  };

  useEffect(() => {
    if (show && onOffAlert) {
      openNotice();
      close();
    }
  }, [show, onOffAlert]);

  if (!show) {
    return null;
  }
};

export default PopupNotice;
