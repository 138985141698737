import { yupResolver } from '@hookform/resolvers/yup';
import { Col, Row, Space } from 'antd';
import { DefaultOptionType } from 'antd/es/select';
import React, { forwardRef, useEffect, useRef, useState } from 'react';
import { FieldError, FieldValues, FormProvider, SubmitHandler, UseFormReturn, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import AppSubLabel from '@/components/app-label/AppSubLabel';
import AppTooltip from '@/components/app-tooltip/AppTooltip';
import { BaseButton } from '@/components/base-button/BaseButton';
import { BaseCard } from '@/components/base-card/BaseCard';
import BaseModal from '@/components/base-modal/BaseModal';
import { TYPE_ICON, showBasePopup } from '@/components/base-popup/BasePopup';
import { EditorWithCounter } from '@/components/form-box-editor';
import { FormInput } from '@/components/form-input/FormInput';
import { FormSelect } from '@/components/form-select/FormSelect';

import useAuthorization from '@/hooks/useAuthorization';
import useFetch from '@/hooks/useFetch';

import {
  EMAIL_REGEX,
  ERROR_CODE,
  ERROR_EMAIL_DUPLICATE,
  EXTERNAL_ADMIN,
  EXTERNAL_GENERAL_USER,
  INTERNAL_ADMIN,
  INTERNAL_GENERAL_USER,
  NOTE_FILED_MAX_LENGTH
} from '@/utils/constants/AppConstants';
import { STAKEHOLDER_VIEW_URL } from '@/utils/constants/RouteContants';
import { INTERNAL_COMPANY_ID } from '@/utils/constants/account';
import { DataViewer } from '@/utils/helpers/common';
import { validationEmail } from '@/utils/services/ProjectApiService';
import { createUser, requestUser } from '@/utils/services/UserApiService';

import Approve from '@/assets/icons/Check.svg';
import CheckedIcon from '@/assets/icons/Checked.svg';
import PlusIcon from '@/assets/icons/PlusIcon.svg';
import Reject from '@/assets/icons/Reject.svg';
import WarningWaitingApprove from '@/assets/icons/WarningWaitingApprove.svg';
import Close from '@/assets/icons/close.svg';
import Warning from '@/assets/icons/template/icon-warning.svg';

import { FormCreateModal } from '../FormValidation';
import { MAX_FIELD, TYPE_STAKEHOLDER } from '../constants';
import { IAccount, IFormMethodStakeholder, IStakeholderProps } from '../models';

type Props = {
  formMethod: UseFormReturn<IFormMethodStakeholder, any>;
  companyOptions: DefaultOptionType[];
};

const StakeholderModal = forwardRef((props: Props) => {
  const { formMethod, companyOptions } = props;
  const { t } = useTranslation();
  const { id } = useParams();
  const wrapperRef = useRef<any>(null);

  const { isAdminRole, isUserRole, isInternalUser, isExternalUser } = useAuthorization();
  const [showPopupCreateAccount, setShowPopupCreateAccount] = useState<boolean>(false);
  const [roleOptions, setRoleOptions] = useState<DefaultOptionType[]>([]);
  const { data: roleData } = useFetch<DefaultOptionType[]>(`/usr/roles`, 'GET');
  const [rolesByCompany, setRolesByCompany] = useState<DefaultOptionType[]>([]);
  const [stakeholdersValidation, setStakeholdersValidation] = useState<IAccount[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [indexAccount, setIndexAccount] = useState<number>(0);
  const projectStakeholders = formMethod.watch('projectStakeholders');
  const applicantCompany = formMethod.watch('applicantCompany');
  const formCreateMethod = useForm<IStakeholderProps>({
    mode: 'all',
    resolver: yupResolver(FormCreateModal)
  });
  const { isDirty } = formCreateMethod.formState;
  const { fields, append, remove } = useFieldArray({
    name: 'projectStakeholders',
    control: formMethod.control
  });

  useEffect(() => {
    if (roleData) {
      setRoleOptions(
        roleData.map((i) => ({
          value: i?.id,
          label: i?.name,
          type: i?.code
        }))
      );
    }
    if (applicantCompany) {
      const companyId = applicantCompany?.value;
      if (!companyId) {
        setRolesByCompany(roleOptions);
      } else {
        setRolesByCompany(
          roleOptions.filter((role) => {
            return companyId === INTERNAL_COMPANY_ID
              ? [INTERNAL_ADMIN, INTERNAL_GENERAL_USER].includes(role.type)
              : [EXTERNAL_ADMIN, EXTERNAL_GENERAL_USER].includes(role.type);
          })
        );
      }
    }
  }, [roleData, applicantCompany]);

  const addNewStakeholders = () => {
    if (fields.length >= MAX_FIELD) return;
    const largestDisplayOrderCurrent = Number(fields[fields.length - 1]?.displayOrder);
    const resultDisplayOrder = isNaN(largestDisplayOrderCurrent) ? 0 : largestDisplayOrderCurrent;
    append({ stakeholderEmail: '', isNewAccount: true, displayOrder: resultDisplayOrder + 1 });
  };

  const watchFields = (fields: any[]) => {
    const watchFields = formMethod.watch(fields);
    const watchFieldsValid = watchFields.filter((field: any) => field);
    return watchFieldsValid.length;
  };

  const handleRemove = (index: number) => {
    remove(index);
  };

  const handleBlur = (index: number) => {
    const account = formMethod.getValues(`projectStakeholders.${index}`);
    const email = account.stakeholderEmail;
    if (isEmail(email)) {
      handleValidateAccount();
    }
  };
  const resultEmails = async (emails: string[], companyId: string) => {
    const params = { emails, companyId };
    try {
      const responses = await validationEmail(params);
      return responses.data;
    } catch (error) {}
  };
  const handleValidateAccount = async () => {
    const companyId = applicantCompany?.value;
    if (!companyId || !projectStakeholders?.length) return;

    const arrayEmail = projectStakeholders.filter((project) => isEmail(project.stakeholderEmail)).map((project) => project.stakeholderEmail);

    const result = await resultEmails(arrayEmail, companyId);
    setStakeholdersValidation(result);
  };
  const isEmail = (email: string) => {
    return email && EMAIL_REGEX.test(email);
  };

  const approveStakeholder = (index: number, isApprove: boolean) => {
    const dataChange = isApprove ? { stakeholderStatus: true } : { rejected: true };
    if (projectStakeholders) {
      const updatedStakeholder = { ...projectStakeholders[index], ...dataChange };
      return formMethod.setValue(`projectStakeholders.${index}`, updatedStakeholder);
    }
  };

  const handleCloseCreateModal = async () => {
    if (!isDirty) {
      setShowPopupCreateAccount(false);
      return;
    }

    const showPopup = await showBasePopup({
      title: String(t('dialog:cancel_editing:header')),
      msg: String(t('dialog:cancel_editing:content')),
      type: TYPE_ICON.REFERENCE
    });
    if (showPopup !== TYPE_ICON.CONFIRM) return;
    formCreateMethod.reset();
    setShowPopupCreateAccount(false);
  };

  const openPopupCreateStakeholder = (index: number) => {
    const emailCreate = formMethod.getValues(`projectStakeholders.${index}.stakeholderEmail`);
    const displayOrder = formMethod.getValues(`projectStakeholders.${index}.displayOrder`);
    formCreateMethod.setValue('stakeholderName', '');
    formCreateMethod.setValue('stakeholderCompany', applicantCompany);
    formCreateMethod.setValue('stakeholderEmail', emailCreate);
    formCreateMethod.setValue('displayOrder', displayOrder);
    formCreateMethod.setValue('role', null);
    if (isUserRole) {
      const roleInternal = rolesByCompany.find((item: any) =>
        isInternalUser ? item.type === INTERNAL_GENERAL_USER : item.type === EXTERNAL_GENERAL_USER
      );
      const roleVal = roleInternal
        ? {
            label: String(roleInternal.label),
            value: String(roleInternal.value)
          }
        : null;
      formCreateMethod.setValue('role', roleVal);
    }
    setTimeout(() => formCreateMethod.reset((pre) => ({ ...pre, isDirty: false })), 0);
    setShowPopupCreateAccount(true);
    setIndexAccount(index);
  };

  const handleSubmitStakeholder = () => {
    formCreateMethod.handleSubmit(createOrRequestAccount, onError)();
  };

  const createOrRequestAccount: SubmitHandler<IStakeholderProps> = async (formData) => {
    const payload = {
      projectId: id,
      email: formData.stakeholderEmail,
      name: formData.stakeholderName,
      organizationId: formData.stakeholderCompany?.value,
      roleId: formData.role?.value,
      upn: formData.stakeholderEmail,
      status: true,
      urlRedirect: STAKEHOLDER_VIEW_URL(String(id))
    };
    try {
      setLoading(true);
      const { data } = isAdminRole ? await createUser(payload) : await requestUser(payload);
      setShowPopupCreateAccount(false);
      formMethod.setValue(`projectStakeholders.${indexAccount}.stakeholderId`, data.id);
      formMethod.setValue(`projectStakeholders.${indexAccount}.stakeholderEmail`, data.email);
      formMethod.setValue(`projectStakeholders.${indexAccount}.stakeholderName`, formData.stakeholderName);
      formMethod.setValue(`projectStakeholders.${indexAccount}.role`, formData.role);
      formMethod.setValue(`projectStakeholders.${indexAccount}.stakeholderCompany`, formData.stakeholderCompany);
      formMethod.setValue(`projectStakeholders.${indexAccount}.isPending`, !isAdminRole);
      formMethod.setValue(`projectStakeholders.${indexAccount}.displayOrder`, formData.displayOrder);
      formMethod.setValue(`projectStakeholders.${indexAccount}.stakeholderStatus`, isAdminRole);
      formMethod.setValue(`projectStakeholders.${indexAccount}.isNewAccount`, false);
      formMethod.clearErrors(`projectStakeholders.${indexAccount}.stakeholderEmail`);
      setLoading(false);
    } catch (error: any) {
      if (error?.response) {
        const {
          data: { fields }
        } = error.response;
        if (fields[0].errorCode) {
          const errorMapping = {
            [ERROR_CODE.USER_WAITING_CREATE]: String(t('common:MSG_P_032')),
            [ERROR_CODE.USER_RESTRICT]: String(t('common:MSG_P_033')),
            [ERROR_CODE.FIELD_NOT_FOUND]: String(t('common:MSG_P_033')),
            [ERROR_CODE.USER_INACTIVE]: String(t('common:MSG_P_034')),
            [ERROR_EMAIL_DUPLICATE]: String(t('common:MSG_P_022'))
          };
          const message = errorMapping[fields[0].errorCode] ?? String(t('common:MSG_C_014'));
          formCreateMethod.setError('stakeholderEmail', { type: 'accountInvalid', message });
        }
      }
    } finally {
      setLoading(false);
    }
  };

  const renderStakeholderInfo = (name?: string, status?: boolean, typePending?: boolean) => {
    const stakeholderName = status ? `(${t('announcement:invalid')})${name}` : name;
    return (
      <div className='flex flex-col gap-[8px]'>
        <span className='font-medium leading-[22.4px]'>{t('stakeholder:label:user_name')}</span>
        <div className='flex items-center gap-2 h-[38px]'>
          <AppTooltip className='block truncate' title={stakeholderName}>
            <span className='body-500'>{stakeholderName}</span>
          </AppTooltip>
          {typePending ? <WarningWaitingApprove className='shrink-0' /> : <CheckedIcon className='icon-check shrink-0' />}
        </div>
      </div>
    );
  };

  const renderStakeholderApprove = (field: any, index: number, stakeholder: IStakeholderProps, validation?: IAccount, error?: FieldError) => {
    const allStatusFalse = !stakeholder.isNewAccount && !stakeholder.stakeholderStatus && !stakeholder.rejected && !stakeholder.waitCreateAccount;
    let renderNameInfo = null;
    if ((validation?.waitingCreate && validation.errorCode === ERROR_CODE.USER_WAITING_CREATE) || (field.waitCreateAccount && field.canDelete)) {
      renderNameInfo = renderStakeholderInfo(validation?.name ?? stakeholder.stakeholderName, false, true);
    } else if (!error && (validation?.name ?? stakeholder.stakeholderName)) {
      renderNameInfo = renderStakeholderInfo(validation?.name ?? stakeholder.stakeholderName, allStatusFalse);
    }
    return (
      <BaseCard
        key={field.id}
        showIconRemove={fields.length > 1}
        index={index}
        onRemove={handleRemove}
        title={`${t('stakeholder:title')}${index + 1}`}
        total={1}
        questions={watchFields([`projectStakeholders.${index}.stakeholderEmail`])}
      >
        <Row gutter={16}>
          <Col span={12}>
            <FormInput
              key={field.id}
              required={true}
              disabled={allStatusFalse}
              warning={
                (!!validation?.waitingCreate && validation.errorCode === ERROR_CODE.USER_WAITING_CREATE) ||
                (field.waitCreateAccount && field.canDelete)
              }
              warningMsg={t('common:MSG_P_032') ?? ''}
              defaultValue={stakeholder.stakeholderEmail}
              name={`projectStakeholders.${index}.stakeholderEmail`}
              labelTx='stakeholder:label:email_address'
              placeholderTx={String(t('common:MSG_001_textbox', { field: t('stakeholder:label:email_address') }))}
              onBlur={() => handleBlur(index)}
            />
          </Col>

          {isEmail(stakeholder.stakeholderEmail) && (
            <Col span={12}>
              {error && (error?.type === ERROR_CODE.ENTITY_NOT_FOUND || error?.type === ERROR_CODE.ENTITY_REJECTED) && (
                <div className='mt-[30px]'>
                  <BaseButton type='secondary' size='medium' onClick={() => openPopupCreateStakeholder(index)}>
                    {isAdminRole ? t('stakeholder:button:create_account') : t('stakeholder:button:request_create_account')}
                  </BaseButton>
                </div>
              )}
              {renderNameInfo}
            </Col>
          )}
        </Row>
      </BaseCard>
    );
  };

  const renderStakeholderPending = (field: any, index: number, stakeholder: IStakeholderProps) => {
    return (
      <BaseCard
        key={field.id}
        showIconRemove={stakeholder.rejected && !stakeholder.waitCreateAccount && fields.length > 1}
        index={index}
        onRemove={handleRemove}
        className='form-title'
        title={`${t('stakeholder:title')}${index + 1}`}
        questions={watchFields([`projectStakeholders.${index}.rejectReason`])}
      >
        <div className='d-flex mb-2'>
          <div className='mr-12'>
            <AppSubLabel
              label={t('stakeholder:label:email_address')}
              subLabel={stakeholder.stakeholderStatus ? '' : stakeholder.rejected ? t('stakeholder:label:remanded') : t('stakeholder:label:pending')}
              title={stakeholder.stakeholderEmail}
              icon={stakeholder.stakeholderStatus ? '' : stakeholder.rejected ? <Close className='w-[18px] ver-align-bottom' /> : <Warning />}
              classSubLabel={stakeholder.stakeholderStatus ? '' : stakeholder.rejected ? 'rejected' : 'waiting-approve'}
              maxWidthLabel={'max-w-[calc(100vw-710px)]'}
            />
          </div>
          {!stakeholder.stakeholderStatus && !stakeholder.rejected && isAdminRole && (
            <>
              <AppTooltip
                className='truncate text-[#12212E] font-bold ant-dropdown-trigger'
                title={t('account_master:list:admit')}
                onClick={() => approveStakeholder(index, true)}
              >
                <Approve className='w-[30px] h-[30px]' />
              </AppTooltip>
              <AppTooltip
                className='ml-3 truncate text-[#12212E] font-bold ant-dropdown-trigger'
                title={t('account_master:list:remand')}
                onClick={() => approveStakeholder(index, false)}
              >
                <Reject className='w-[30px] h-[30px]' />
              </AppTooltip>
            </>
          )}
        </div>
        {stakeholder.rejected && stakeholder.waitCreateAccount && (
          <div className='mb-2'>
            <EditorWithCounter
              name={`projectStakeholders.${index}.rejectReason`}
              limit={NOTE_FILED_MAX_LENGTH}
              toolbar={null}
              label={t('stakeholder:label:reason_remand:label') ?? ''}
              required={true}
              className={'ml-[185px] mt-1'}
              styleCounterLabel={'text-gray-3'}
              placeholder={t('stakeholder:label:reason_remand:placeholder') ?? ''}
              editorWrapperProps={{ className: '!h-[70px]' }}
            />
          </div>
        )}
        {stakeholder.rejectReason && !stakeholder.waitCreateAccount && (
          <div className='ml-[185px] wordWrap-break mb-2 flex gap-[16px]'>
            <span className='w-[90px] shrink-0'>{t('stakeholder:label:reason_remand:label')}</span>
            <AppTooltip
              className='font-bold max-h-[140px] overflow-auto'
              title={
                <span
                  className='tooltip-title'
                  dangerouslySetInnerHTML={{ __html: DataViewer.displayAsSanitizeHTML(String(stakeholder.rejectReason)) }}
                />
              }
            >
              <div>
                <span dangerouslySetInnerHTML={{ __html: DataViewer.displayAsSanitizeHTML(String(stakeholder.rejectReason)) }} />
              </div>
            </AppTooltip>
          </div>
        )}
        <AppSubLabel label={t('stakeholder:label:user_name')} title={stakeholder.stakeholderName} />
        <AppSubLabel
          label={t('stakeholder:label:company_name')}
          title={stakeholder.stakeholderCompanyView ?? stakeholder.stakeholderCompany?.label}
        />
        <AppSubLabel label={t('stakeholder:label:privileges')} title={stakeholder.roleView ?? stakeholder.role?.label} />
      </BaseCard>
    );
  };
  const renderMap = {
    [TYPE_STAKEHOLDER.APPROVE]: renderStakeholderApprove,
    [TYPE_STAKEHOLDER.PENDING]: renderStakeholderPending
  };

  const getRenderFunctionKey = (field: any, stakeholder: IStakeholderProps) => {
    const conditions = {
      isPending: field.rejected || (field.waitCreateAccount && !field.canDelete),
      isApproved:
        (field.stakeholderStatus || (!field.isNewAccount && !field.stakeholderStatus && !field.rejected && !field.waitCreateAccount)) &&
        !stakeholder.isPending
    };

    if (conditions.isPending) {
      return TYPE_STAKEHOLDER.PENDING;
    }
    if (conditions.isApproved) {
      return TYPE_STAKEHOLDER.APPROVE;
    }
    return stakeholder.isPending ? TYPE_STAKEHOLDER.PENDING : TYPE_STAKEHOLDER.APPROVE;
  };

  const renderStakeholderByStatus = (field: any, index: number) => {
    const stakeholder = formMethod.getValues(`projectStakeholders.${index}`);
    const stakeholderValidation = stakeholdersValidation?.find((item) => item.email.toLowerCase() === stakeholder.stakeholderEmail.toLowerCase());
    const errorStakeholder = formMethod.formState.errors.projectStakeholders?.[index]?.stakeholderEmail;
    const renderFunctionKey = getRenderFunctionKey(field, stakeholder);
    const renderFunction = renderMap[renderFunctionKey];

    return renderFunction(field, index, stakeholder, stakeholderValidation, errorStakeholder);
  };

  const onError = (errors: FieldValues) => {};

  return (
    <>
      {fields.map((field, index) => {
        return renderStakeholderByStatus(field, index);
      })}
      <Space size={16}>
        <div className='max-w-[134px]'>
          <BaseButton icon={<PlusIcon />} disabled={fields.length === MAX_FIELD} type='secondary' block size='medium' onClick={addNewStakeholders}>
            {t('stakeholder:button:add_stakeholder')}
          </BaseButton>
        </div>
      </Space>
      <div ref={wrapperRef}>
        <BaseModal
          getContainer={wrapperRef.current}
          title={<span className='title-24'>{isAdminRole ? t('template_layout:template_create') : t('template_layout:template_request')}</span>}
          onCancel={handleCloseCreateModal}
          maskClosable={false}
          openModal={showPopupCreateAccount}
          destroyOnClose
          contentElement={
            <FormProvider {...formCreateMethod}>
              <Row gutter={16} className='gap-4'>
                <Col span={24}>
                  <FormInput
                    required={true}
                    defaultValue={formCreateMethod.getValues('stakeholderName')}
                    name='stakeholderName'
                    labelTx='basic_information:username'
                    placeholderTx={String(t('placeholder:text_box', { field: t('basic_information:username') }))}
                  />
                </Col>
                <Col span={24}>
                  <FormInput
                    required={true}
                    defaultValue={formCreateMethod.getValues('stakeholderEmail')}
                    name='stakeholderEmail'
                    labelTx='basic_information:email_address'
                    placeholderTx={String(t('placeholder:text_box', { field: t('basic_information:email_address') }))}
                  />
                </Col>
                <Col span={24}>
                  <FormSelect
                    disabled
                    required={true}
                    labelInValue
                    name='stakeholderCompany'
                    label={String(t('basic_information:company_name'))}
                    placeholder={String(t('placeholder:select', { field: t('basic_information:company_name') }))}
                    options={companyOptions}
                  />
                </Col>
                <Col span={24}>
                  <FormSelect
                    labelInValue
                    required={true}
                    disabled={isExternalUser || (isInternalUser && applicantCompany?.value === INTERNAL_COMPANY_ID)}
                    name='role'
                    label={String(t('account_list:role'))}
                    placeholder={String(t('placeholder:select', { field: t('account_list:role') }))}
                    options={rolesByCompany}
                  />
                </Col>
                <Col span={24} className='flex justify-end gap-4'>
                  <BaseButton type='tertiary' size='medium' className='min-w-[120px]' onClick={handleCloseCreateModal}>
                    <span> {t('button:cancel')} </span>
                  </BaseButton>
                  <BaseButton disabled={loading} size='medium' onClick={handleSubmitStakeholder}>
                    <span> {isAdminRole ? t('button:create') : t('common:button:send')} </span>
                  </BaseButton>
                </Col>
              </Row>
            </FormProvider>
          }
        ></BaseModal>
      </div>
    </>
  );
});

export default StakeholderModal;
