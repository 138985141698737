import Pin from '@/layouts/assets/icons/Pin.svg';
import Pinned from '@/layouts/assets/icons/Pinned.svg';
import React, { useRef, useState } from 'react';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import EmptyDataWithIcon from '@/components/EmptyData/EmptyDataWithIcon';
import AppTooltip from '@/components/app-tooltip/AppTooltip';
import { BaseButton } from '@/components/base-button/BaseButton';
import BaseTableV2 from '@/components/common/table-fetch';
import CommonTag from '@/components/common/tag/CommonTag';

import { setAlertNotification } from '@/redux/globalReducer';

import useAuthorization, { C, DELETE_ANNOUNCEMENT_LIST, V } from '@/hooks/useAuthorization';
import { useFilterConfigs } from '@/hooks/useFilterConfigs';
import useMutation from '@/hooks/useMutation';

import { ROUTER_IDS } from '@/utils/constants';
import { TYPE } from '@/utils/constants/AppConstants';
import { HTTP_STATUS_CODE } from '@/utils/constants/Http';
import { CREATE_ANNOUNCEMENT_URL, NOT_EXITS_URL, VIEW_ANNOUNCEMENT_URL } from '@/utils/constants/RouteContants';
import { CREATE_NEW_ANNOUNCEMENT_LIST, TABLE_ANNOUNCEMENT_LIST } from '@/utils/constants/announcement';
import { DataViewer } from '@/utils/helpers/common';
import { formatDateTime, getEndOfDay } from '@/utils/helpers/globalHelper';
import { IAnnouncementInterfaceRow } from '@/utils/interfaces/announcementInterface';

import IconAddNew from '@/assets/icons/project/icon-add-new.svg';

import { STATUS } from '../add-edit/contants';
import TableHeader from './Header';
import { TYPE_CONFIRM } from './constants';
import { IQueryFilterParams } from './models';
import { generateFilter } from './utils';

import './List.scss';

export default function List() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isInternalRole, hasPermission } = useAuthorization();

  const navigate = useNavigate();
  const { id } = useParams();

  const tableRef = useRef<any>(null);
  const headerPageRef = useRef<HTMLDivElement>(null);

  const { data: defaultFilterConfigs, update: updateFilterConfigs } = useFilterConfigs<FieldValues>(ROUTER_IDS.ANNOUNCEMENT_LIST);

  const { mutate: deleteAnnouncements } = useMutation(`/prj/projects/${id}/announcements`, {
    method: 'DELETE',
    showToastError: true
  });

  const { mutate: pinAnnouncements } = useMutation(`/prj/projects/${id}/announcements/pin`, {
    method: 'POST',
    bodyType: 'json',
    showToastError: true
  });

  const { mutate: updatedAnnouncement } = useMutation(`/prj/projects/${id}/announcements/{notiId}/readed`, {
    method: 'POST',
    bodyType: 'json',
    showToastError: true,
    showToastSuccess: false
  });

  const generateDefaultFilterForm = () => {
    const results = {
      form: {},
      query: {}
    };
    if (!defaultFilterConfigs) return results;
    const valueFilterDefault = defaultFilterConfigs[String(id)];
    if (!valueFilterDefault) return results;
    const { publishedDateTo } = valueFilterDefault;
    results.form = {
      primary: {
        ...valueFilterDefault,
        publishedDateTo: publishedDateTo ? formatDateTime(publishedDateTo) : undefined
      }
    };
    results.query = {
      filter: generateFilter({
        keyword: '',
        confirmationCheck: valueFilterDefault?.confirmationCheck,
        publishedDateFrom: valueFilterDefault?.publishedDateFrom ?? undefined,
        publishedDateTo: valueFilterDefault?.publishedDateTo ?? undefined
      }),
      picIdsSearch: valueFilterDefault?.targetAudience ?? []
    };
    return results;
  };

  const formFilter = useForm({ defaultValues: generateDefaultFilterForm().form });
  const [filter, setFilter] = useState(generateDefaultFilterForm().query);

  const updateFilter = (values: FieldValues) => {
    const publishedDateFrom = values?.primary?.publishedDateFrom ? formatDateTime(values?.primary?.publishedDateFrom) : undefined;
    const publishedDateTo = values?.primary?.publishedDateTo ? getEndOfDay(values?.primary?.publishedDateTo) : undefined;
    const formValues: IQueryFilterParams = {
      keyword: values?.search,
      confirmationCheck: values?.primary?.confirmationCheck,
      publishedDateFrom,
      publishedDateTo
    };
    setFilter({ filter: generateFilter(formValues), picIdsSearch: values?.primary?.targetAudience ?? [] });
    updateFilterConfigs({
      ...defaultFilterConfigs,
      [String(id)]: {
        ...values.primary,
        publishedDateFrom,
        publishedDateTo
      }
    });
  };

  const addNewAnnouncement = () => {
    navigate(CREATE_ANNOUNCEMENT_URL(id ?? ''));
  };

  const handlePinNotification = async (value: IAnnouncementInterfaceRow) => {
    const payloadPin = {
      announcementId: value.id,
      version: value.version ?? 1
    };
    const responses = await pinAnnouncements(payloadPin);
    if (!responses) return 0;
    const { status } = responses;
    if (status === 200) {
      tableRef.current?.refreshData();
    }
  };

  const handleDelete = async (keys: React.Key[], records: any[]) => {
    const ids = records.map((item) => ({ id: item.id, version: item.version }));
    const responses = await deleteAnnouncements({ ids });
    if (!responses) return 0;
    const { data } = responses;
    if (data.successCount === keys.length) {
      dispatch(
        setAlertNotification({
          show: true,
          type: TYPE.SUCCESS,
          message: t('common:MSG_C_003', { item: t('common:button:delete') })
        })
      );
    }

    if (data.successCount < keys.length) {
      dispatch(
        setAlertNotification({
          show: true,
          type: TYPE.SUCCESS,
          message: t('common:MSG_C_020', { deletable: data.successCount, total: keys.length })
        })
      );
    }
    return data.successCount;
  };

  const gotoDetail = (record: any) => {
    if (!id || !record.id) return;
    navigate(VIEW_ANNOUNCEMENT_URL(id, record.id));
  };

  const handleViewDetail = async (record: any) => {
    if (isInternalRole) {
      gotoDetail(record);
      return;
    }

    try {
      const result = await updatedAnnouncement({ version: record.version }, { notiId: record.id });
      if (result?.status === HTTP_STATUS_CODE.SUCCESS) {
        gotoDetail(record);
      } else navigate(NOT_EXITS_URL);
    } catch (error) {}
  };

  const renderAnnouncementMasterListColumns = [
    {
      title: t('announcement:list:label:title'),
      dataIndex: 'userName',
      width: 780,
      render(_: any, record: IAnnouncementInterfaceRow) {
        return (
          <div className='block max-w-full w-fit truncate'>
            <span
              className={`mr-1 icon-pin ${hasPermission(DELETE_ANNOUNCEMENT_LIST, C) ? 'ant-dropdown-trigger' : ''}`}
              onClick={hasPermission(DELETE_ANNOUNCEMENT_LIST, C) ? () => handlePinNotification(record) : undefined}
            >
              {record?.pin ? <Pinned /> : hasPermission(DELETE_ANNOUNCEMENT_LIST, C) ? <Pin /> : ''}
            </span>
            <div onClick={() => handleViewDetail(record)} style={{ display: 'inline' }} className='link-custom cursor-pointer font-medium'>
              <AppTooltip title={record.title}>
                {(() => {
                  switch (record?.status) {
                    case STATUS.WAITING:
                      return <span className='waiting-approve'>{t('announcement:list:label:approval_pending')}</span>;
                    case STATUS.SAVE:
                      return <span className='rejected'>{t('announcement:list:label:unpublished')}</span>;
                    default:
                      return '';
                  }
                }).call(this)}
                <span className='underline text-[14px]'>{record.title}</span>
              </AppTooltip>
            </div>
          </div>
        );
      }
    },
    {
      title: t('announcement:list:label:target_audience'),
      dataIndex: 'pic',
      width: 200,
      render(_: any, record: IAnnouncementInterfaceRow) {
        const tooltipText = record.pic.map((item) => `${!item.userStatus ? `(${t('announcement:invalid')})` : ''}${item.userName}`).join(', ');
        return (
          <AppTooltip className='block max-w-full w-fit truncate text-[14px]' title={tooltipText}>
            {tooltipText}
          </AppTooltip>
        );
      }
    },
    {
      title: t('announcement:list:label:confirmation_check'),
      dataIndex: 'waitingCreate',
      width: 200,
      render(_: any, record: IAnnouncementInterfaceRow) {
        return (
          <AppTooltip className='block max-w-full w-fit truncate text-[14px]'>
            {(() => {
              switch (record.confirmedStatus) {
                case TYPE_CONFIRM.confirm:
                  return <CommonTag type={'success'} text={t('announcement:list:filter:confirmation_check:confirmed')} />;
                case TYPE_CONFIRM.unConfirm:
                  return <CommonTag type={'default'} text={t('announcement:list:filter:confirmation_check:unconfirmed')} />;
                case TYPE_CONFIRM.noData:
                  return <>{record.confirmedStatus}</>;
                case TYPE_CONFIRM.emptyData:
                  return DataViewer.displayTime(null);
                default:
                  return <CommonTag type={'default'} text={record.confirmedStatus} />;
              }
            }).call(this)}
          </AppTooltip>
        );
      }
    },
    {
      title: t('announcement:list:label:send_date'),
      dataIndex: 'publishedDate',
      key: 'publishedDate',
      width: 200,
      render: (_: any, record: IAnnouncementInterfaceRow) => (
        <AppTooltip className='text-[14px]' title={formatDateTime(record?.publishedDate ?? '')}>
          {formatDateTime(record?.publishedDate ?? '')}
        </AppTooltip>
      ),
      sorter: true
    }
  ];

  return (
    <div className='announcement-list'>
      <div ref={headerPageRef} className='grid grid-cols-2 gap-4 page-list-header mb-[12px]'>
        <div className='title-24'>{t('announcement:list:title')}</div>
        <div className='text-end'>
          {hasPermission(CREATE_NEW_ANNOUNCEMENT_LIST, V) && (
            <BaseButton size='medium' icon={<IconAddNew style={{ verticalAlign: 'sub' }} />} onClick={addNewAnnouncement} className='float-right'>
              {t('announcement:list:button:add')}
            </BaseButton>
          )}
        </div>
      </div>
      {hasPermission(TABLE_ANNOUNCEMENT_LIST, V) && (
        <div className='page-data'>
          <BaseTableV2
            ref={tableRef}
            tableHeader={
              <FormProvider {...formFilter}>
                <TableHeader onSubmit={updateFilter}></TableHeader>
              </FormProvider>
            }
            apiEndpoint={`/prj/projects/${id}/announcements/search`}
            apiMethod='POST'
            columns={renderAnnouncementMasterListColumns}
            filterDefault={filter}
            scrollTable={{ maxHeight: '100vh - 239px' }}
            showDelete={true}
            handleDeleteClick={hasPermission(DELETE_ANNOUNCEMENT_LIST, C) ? handleDelete : undefined}
            rowSelection={!hasPermission(DELETE_ANNOUNCEMENT_LIST, C) ? null : {}}
            deleteConfirmPopup={{
              type: 'discard',
              title: t('announcement:list:delete:title') ?? '',
              msg: t('announcement:list:delete:label') ?? ''
            }}
            defaultSort={[
              { field: 'pin', order: 'descend' },
              { field: 'publishedDate', order: 'descend' }
            ]}
            emptyDataWithoutFilter={
              <EmptyDataWithIcon
                title={{
                  content: 'announcement:list:no_data:title'
                }}
                description={{
                  content: 'announcement:list:no_data:description'
                }}
                button={
                  hasPermission(DELETE_ANNOUNCEMENT_LIST, C)
                    ? {
                        label: 'announcement:list:button:add',
                        props: {
                          onClick: addNewAnnouncement
                        }
                      }
                    : null
                }
              />
            }
          />
        </div>
      )}
    </div>
  );
}
