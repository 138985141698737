import { useAppDispatch } from '@/hooks';
import CommentWidget from '@/widgets/Comment';
import { IProjectDetail } from '@/widgets/Comment/models';
import { yupResolver } from '@hookform/resolvers/yup';
import { Collapse, CollapseProps, Space, Tooltip } from 'antd';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { debounce } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMatch, useNavigate, useParams } from 'react-router-dom';

import { TaskDetailContext } from '@pages/project-management/task/view';
import { TaskDetailQuickUpdateForm } from '@pages/project-management/task/view/components/TaskDetailQuickUpdateForm';

import { BaseButton } from '@/components/base-button/BaseButton';
import { showBasePopup } from '@/components/base-popup/BasePopup';
import { ConfirmCancelEditing } from '@/components/confirm-popup';
import { FormAttachmentTable } from '@/components/form-attachment-table/FormAttachmentTable';
import { FILE_ATTACHMENT_STATUS } from '@/components/form-attachment/FileAttachment';
import { EditorWithCounter } from '@/components/form-box-editor';
import { CaretDownBold } from '@/components/icon-svg/IconSvg';

import { setAlertNotification } from '@/redux/globalReducer';

import useAuthorization, { C } from '@/hooks/useAuthorization';
import useFetch from '@/hooks/useFetch';
import useMutation from '@/hooks/useMutation';
import useResize from '@/hooks/useResize';

import { API } from '@/utils/constants/Apis';
import { ERROR_CODE, ERROR_DUPLICATE, MAPPING_ERROR_CODE_KEY, NOTE_MAX_LENGTH, TYPE, UPLOAD_STATUS } from '@/utils/constants/AppConstants';
import { APPROVED_REJECT_TASK_PROJECT_ATTACHMENT } from '@/utils/constants/AuthorizationProjectManagement';
import { HTTP_STATUS_CODE } from '@/utils/constants/Http';
import { ADD_TASK_OF_PROJECT_URL, VIEW_PROJECT_URL, VIEW_TASK_OF_PROJECT_URL } from '@/utils/constants/RouteContants';
import { getDetailTaskOfProject, getProjectInfo } from '@/utils/services/ProjectApiService';

import WarningCircle from '@/assets/icons/WarningCircle.svg';

import FormGroupTitleCategoryProcessOfTask from '../components/FormGroupTitleCategoryProcessOfTask';
import TaskInternalDocuments from '../components/TaskInternalDocuments';
import TaskRequiredDocuments from '../components/TaskRequiredDocuments';
import { ATTACHMENT_STATUS, DEFAULT_VALUE_FORM_TASK_OF_PROJECT } from '../constants';
import {
  IDocument,
  IFileAttachment,
  IFormAddTaskOfProject,
  IFormDetailTask,
  ProjectTaskAttachmentFile,
  ProjectTaskPic,
  TaskRequireDocument,
  TaskRequireDocumentAttachmentFile
} from '../models';
import { FormDetailTaskData } from '../view/Validation';
import HeaderTaskDetail from '../view/components/Header';
import { FormAddTaskOfProjectValidation, FormEditTaskOfProjectValidation } from './Validation';

import './index.scss';

const ACTIVE_KEY = {
  DETAIL: 'detail',
  ATTACHMENT: 'projectTaskAttachmentFiles',
  REQUIRE_DOCUMENT: 'taskRequireDocuments',
  INTERNAL_DOCUMENT: 'taskInternalDocuments'
};

export default function AddEditTaskOfProject() {
  const { t } = useTranslation();
  const { idProject = '', idTask } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { hasPermission, user } = useAuthorization();
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [hasComment, setHasComment] = useState<boolean>(false);
  const [defaultValueForm, setDefaultValueForm] = useState<IFormAddTaskOfProject>(DEFAULT_VALUE_FORM_TASK_OF_PROJECT);
  const [activeKey, setActiveKey] = useState<string[]>([
    ACTIVE_KEY.DETAIL,
    ACTIVE_KEY.ATTACHMENT,
    ACTIVE_KEY.REQUIRE_DOCUMENT,
    ACTIVE_KEY.INTERNAL_DOCUMENT
  ]);
  const [isWarningDeadline, setIsWarningDeadline] = useState(false);

  const modeAdd = useMatch(ADD_TASK_OF_PROJECT_URL(':idProject'));
  const form = useForm<IFormAddTaskOfProject>({
    mode: 'all',
    resolver: yupResolver(modeAdd ? FormAddTaskOfProjectValidation : FormEditTaskOfProjectValidation),
    defaultValues: () => getDetailTask()
  });
  const formHeader = useForm<IFormDetailTask>({
    mode: 'all',
    shouldUnregister: false,
    resolver: yupResolver(FormDetailTaskData),
    defaultValues: {}
  });
  const { isDirty } = form.formState;
  const { mutate: postCreateTaskOfProject, error: errorCreateTask } = useMutation(API.CREATE_TASK_OF_PROJECT, {
    method: 'POST',
    bodyType: 'json',
    showToastError: false,
    showToastSuccess: false
  });
  const { mutate: updateTaskProject } = useMutation(API.UPDATE_TASK_OF_PROJECT(String(idTask) ?? ''), {
    method: 'PUT',
    bodyType: 'json',
    showToastError: true,
    showToastSuccess: false
  });
  const { data: detailTask, fetchData: fetchDetailTask } = useFetch<any>(API.GET_DETAIL_TASK(idProject ?? '', idTask ?? ''), 'GET');
  const { data: project } = useFetch<IProjectDetail>(API.GET_PROJECT_DETAIL(idProject), 'GET', undefined, undefined, { autoFetch: !!idProject });

  useEffect(() => {
    if (errorCreateTask) handleException(errorCreateTask);
  }, [errorCreateTask]);

  const handleChange = (key: string | string[]) => {
    setActiveKey(Array.isArray(key) ? key : [key]);
  };

  useEffect(() => {
    if (detailTask) {
      const { projectTaskPics } = detailTask;
      const convertToOptions = (item: ProjectTaskPic) => ({
        id: item.id,
        label: item.picName,
        value: item.picEmail
      });
      const picValues = projectTaskPics?.map(convertToOptions) ?? [];
      const requiredFiles = detailTask.taskRequireDocuments.map((item: TaskRequireDocument) => {
        return {
          ...item,
          taskRequireDocumentAttachmentFiles: item.taskRequireDocumentAttachmentFiles.map((file: TaskRequireDocumentAttachmentFile) => {
            return {
              ...file,
              status: FILE_ATTACHMENT_STATUS.EDIT
            };
          })
        };
      });
      formHeader.reset(
        {
          deadline: !detailTask?.deadline ? null : dayjs(detailTask.deadline),
          picId: picValues,
          status: detailTask?.prevStatusId || null,
          taskRequireDocuments: requiredFiles
        },
        { keepDirty: false }
      );
    }
  }, [detailTask]);

  const handleCreateTask = () => {
    form.handleSubmit(
      async (data) => {
        setLoading(true);
        const payload = getPayload(data);
        const result = await postCreateTaskOfProject(payload);
        if (result?.status === HTTP_STATUS_CODE.SUCCESS) {
          setIsSubmitted(true);
          setTimeout(() => {
            setLoading(false);
            navigate(VIEW_TASK_OF_PROJECT_URL(String(idProject), result.data));
            const haveFile = payload.projectTaskAttachmentFiles.some((item) => item.status === ATTACHMENT_STATUS.WAITING);
            dispatch(
              setAlertNotification({
                show: true,
                type: TYPE.SUCCESS,
                message: t('common:MSG_C_003', { item: t(haveFile ? 'announcement:approval_request' : 'button:keep') })
              })
            );
          }, 500);
        } else {
          setIsSubmitted(false);
          setLoading(false);
        }
      },
      (err) => {
        for (let key in err) {
          if (activeKey.indexOf(key) === -1) {
            setActiveKey([...activeKey, key]);
          }
        }
        let firstInValidKey: string | undefined = Object.keys(err)?.[0];
        const element = document.getElementById(firstInValidKey);
        if (element) element?.scrollIntoView({ behavior: 'smooth' });
      }
    )();
  };

  const getFormAndOriginalFiles = (form: any[] = [], original: any[] = [], displayOrder: boolean = false) => {
    // detail and form: no item
    if (!form?.length && !original?.length) return [];
    // detail: no item, form: have item
    if (!original?.length && !!form?.length) {
      return form.map((item: any, index: number) => {
        return {
          ...item,
          displayOrder: displayOrder ? index + 1 : item.displayOrder
        };
      });
    }
    // detail: have item, form: no item
    if (!!original?.length && !form?.length) {
      return original.map((item: any) => {
        return {
          ...item,
          deleted: true
        };
      });
    }
  };

  const getTaskAttachmentFiles = (form: (IFileAttachment | IDocument)[] = [], original: (IFileAttachment | IDocument)[] = []) => {
    const filesResult = getFormAndOriginalFiles(form, original, true);
    if (filesResult?.length) return filesResult;

    // detail and form: have item
    if (!!original?.length && !!form?.length) {
      let removeAttachmentFiles: ProjectTaskAttachmentFile[] = [];
      original?.forEach((item: any) => {
        const found = form?.find((i: any) => i.blobPath === item.blobPath);
        if (!found) {
          removeAttachmentFiles.push({
            ...item,
            deleted: true
          });
        }
      });

      return [
        ...removeAttachmentFiles,
        ...form.map((item: any, index: number) => {
          return {
            ...item,
            requestApproval: item.status === ATTACHMENT_STATUS.WAITING,
            displayOrder: index + 1
          };
        })
      ];
    }
  };

  const getDocumentsTaskRequired = (form: IDocument[] = [], original: IDocument[] = []) => {
    const filesResult = getFormAndOriginalFiles(form, original) as IDocument[];
    if (filesResult?.length) return filesResult;

    // detail and form: have item
    original.forEach((item: IDocument, index: number) => {
      const files = form[index]?.taskRequireDocumentAttachmentFiles || [];
      const originalFiles = item.taskRequireDocumentAttachmentFiles;
      const results = getTaskAttachmentFiles(files, originalFiles);
      item.taskRequireDocumentAttachmentFiles = results;
      item.isConfirm = form[index]?.isConfirm;
      item.nameConfirm = form[index]?.nameConfirm;
      item.dateConfirm = form[index]?.dateConfirm;
    });

    // item deleted in original
    let deletedItemOriginal: IDocument[] = [];
    for (const item of original) {
      const found = form?.find((i: IDocument) => i.id === item.id);
      if (!found) {
        deletedItemOriginal.push({
          ...item,
          deleted: true
        });
      }
    }

    // item add in original
    const addItems = [];
    for (const item of form) {
      if (item.id) continue;
      const fileUpdate = [];
      for (const file of item?.taskRequireDocumentAttachmentFiles || []) {
        fileUpdate.push({
          ...file,
          id: null
        });
      }
      addItems.push({ ...item, id: null, taskRequireDocumentAttachmentFiles: [...fileUpdate] });
    }

    // item current
    const formIdsSet = new Set(form.map((i) => i.id));
    const currentItems = original.filter((i) => i.id && formIdsSet.has(i.id));
    return [...deletedItemOriginal, ...currentItems, ...addItems];
  };

  const getDocumentsTaskInternal = (form: IDocument[] = [], original: IDocument[] = []) => {
    const filesResult = getFormAndOriginalFiles(form, original);
    if (filesResult?.length) return filesResult;

    // detail and form: have item
    if (!!original?.length && !!form?.length) {
      // item deleted in original
      let deletedItemOriginal: IDocument[] = [];

      for (const item of original) {
        const found = form?.find((i: IDocument) => i.id === item.id);
        if (!found) {
          deletedItemOriginal.push({
            ...item,
            deleted: true
          });
        }
      }

      return [
        ...deletedItemOriginal,
        ...form.map((item: IDocument) => {
          return {
            ...item
          };
        })
      ];
    }
  };

  const getPayloadUpdate = (valueForm: IFormAddTaskOfProject) => {
    const { projectProcessId, taskId, detail, projectTaskAttachmentFiles, taskInternalDocuments, taskRequireDocuments } = valueForm;
    return {
      projectId: idProject,
      processId: projectProcessId,
      taskMasterId: taskId,
      detail,
      version: detailTask.version,
      projectTaskAttachmentFiles: getTaskAttachmentFiles(projectTaskAttachmentFiles, defaultValueForm.projectTaskAttachmentFiles),
      taskRequireDocuments: getDocumentsTaskRequired(taskRequireDocuments, defaultValueForm.taskRequireDocuments),
      taskInternalDocuments: getDocumentsTaskInternal(taskInternalDocuments, defaultValueForm.taskInternalDocuments)
    };
  };

  const submitForm = () => {
    form.handleSubmit(
      async (data) => {
        setLoading(true);
        const payload = getPayloadUpdate(data);
        const result = await updateTaskProject(payload);
        if (result?.status === HTTP_STATUS_CODE.SUCCESS) {
          setIsSubmitted(true);
          setTimeout(() => {
            setLoading(false);
            navigate(VIEW_TASK_OF_PROJECT_URL(String(idProject), result.data));
            const listTaskIdAttachmentFiles = detailTask.projectTaskAttachmentFiles.map((item: any) => item.id);
            const haveFile = payload?.projectTaskAttachmentFiles?.some(
              (item) => item.status === ATTACHMENT_STATUS.WAITING && !item.deleted && !listTaskIdAttachmentFiles.includes(item.id)
            );
            dispatch(
              setAlertNotification({
                show: true,
                type: TYPE.SUCCESS,
                message: t('common:MSG_C_003', { item: t(haveFile ? 'project:task_of_project:approval_request' : 'button:keep') })
              })
            );
          }, 500);
        } else {
          setIsSubmitted(false);
          setLoading(false);
        }
      },
      (err) => {
        for (let key in err) {
          if (activeKey.indexOf(key) === -1) {
            setActiveKey([...activeKey, key]);
          }
        }
        let firstInValidKey: string | undefined = Object.keys(err)?.[0];
        const element = document.getElementById(firstInValidKey);
        if (element) element?.scrollIntoView({ behavior: 'smooth' });
      }
    )();
  };

  const handleEditTask = async () => {
    if (!hasComment) {
      submitForm();
      setHasComment(false);
    } else {
      const showPopup = await showBasePopup({
        type: 'discard',
        title: t('common:MSG_C_005:title') ?? '',
        msg: t('common:MSG_C_005:description') ?? ''
      });
      if (showPopup !== 'confirm') return;
      submitForm();
      setHasComment(false);
    }
  };

  const handleException = (err: any) => {
    let errorMessage = t('common:MSG_C_014');
    const dataError = err?.response?.data;
    if (dataError) {
      switch (dataError?.errorCode) {
        case ERROR_CODE.VALIDATION:
          if (
            ['ProjectRequiredDocumentId', 'ProjectInternalDocumentId'].includes(dataError?.fields[0]?.name) &&
            dataError?.fields[0]?.errorCode === ERROR_DUPLICATE
          ) {
            errorMessage = t('common:MSG_C_019', {
              field: t(
                dataError.fields[0].name === 'ProjectRequiredDocumentId' ? 'required_documents:required_documents' : 'template_layout:document'
              )
            });
          } else {
            errorMessage = t(MAPPING_ERROR_CODE_KEY[dataError?.fields[0]?.errorCode] ?? 'common:MSG_C_014');
          }
          break;
        default:
          break;
      }
    }
    dispatch(
      setAlertNotification({
        show: true,
        type: TYPE.ERROR,
        message: errorMessage
      })
    );
  };

  const getPayload = (val: IFormAddTaskOfProject) => {
    const { processId, taskMasterId, detail, projectTaskAttachmentFiles, taskInternalDocuments, taskRequireDocuments } = val;

    const getIdAndVersion = (item: any) => ({
      id: idTask ? item.id : null,
      version: idTask ? item.version : null
    });

    const mapFiles = (item: IFileAttachment, index: number) => ({
      ...getIdAndVersion(item),
      name: item.name,
      blobPath: item.blobPath,
      status: item.requestApproval ? ATTACHMENT_STATUS.WAITING : ATTACHMENT_STATUS.DONE,
      displayOrder: index
    });

    const mapDocuments = (item: IDocument, index: number) => ({
      ...getIdAndVersion(item),
      projectInternalDocumentId: item.projectInternalDocumentId,
      displayOrder: item.displayOrder,
      isConfirm: item.isConfirm
    });

    const mapRequiredDocuments = (item: IDocument, index: number) => ({
      ...getIdAndVersion(item),
      isConfirm: item.isConfirm,
      projectRequiredDocumentId: item.projectRequiredDocumentId,
      displayOrder: item.displayOrder,
      taskRequireDocumentAttachmentFiles: item.taskRequireDocumentAttachmentFiles?.map(mapFiles)
    });

    return {
      projectId: idProject,
      processId,
      taskMasterId,
      detail,
      projectTaskAttachmentFiles: projectTaskAttachmentFiles?.map(mapFiles) ?? [],
      taskInternalDocuments: taskInternalDocuments?.filter((item) => item.projectInternalDocumentId).map(mapDocuments) ?? [],
      taskRequireDocuments: taskRequireDocuments?.filter((item) => item.projectRequiredDocumentId).map(mapRequiredDocuments) ?? []
    };
  };

  const getDetailTask = async () => {
    let defaultValue = DEFAULT_VALUE_FORM_TASK_OF_PROJECT;

    try {
      const { data: dataDetailProject } = await getProjectInfo(String(idProject));
      if (idTask) {
        const { data: dataDetailTask } = await getDetailTaskOfProject(String(idProject), String(idTask));
        defaultValue = {
          ...dataDetailTask,
          taskRequireDocuments: dataDetailTask.taskRequireDocuments?.map((item: IDocument) => {
            return {
              ...item,
              taskRequireDocumentAttachmentFiles: item.taskRequireDocumentAttachmentFiles?.map((i: any) => {
                return {
                  ...i,
                  status: UPLOAD_STATUS.DONE
                };
              })
            };
          })
        };
      }
      defaultValue.countryId = dataDetailProject.projectAssignmentInfo.countryId;
      setDefaultValueForm(defaultValue);
      return {
        ...defaultValue,
        projectTaskAttachmentFiles: defaultValue?.projectTaskAttachmentFiles.map((item: any) => {
          return {
            ...item,
            requestApproval: [ATTACHMENT_STATUS.WAITING, ATTACHMENT_STATUS.APPROVAL, ATTACHMENT_STATUS.REJECT].includes(item.status)
          };
        })
      };
    } catch (error) {
      return DEFAULT_VALUE_FORM_TASK_OF_PROJECT;
    }
  };

  const renderExpandIcon = (isActive: boolean | undefined) => {
    return <CaretDownBold type='caret-right' className={`${isActive ? 'rotate-0' : '-rotate-90'}`} />;
  };

  const allowedUser = () => {
    if (!hasPermission(APPROVED_REJECT_TASK_PROJECT_ATTACHMENT, C) || modeAdd) return false;
    const { projectTaskAttachmentFiles } = form.watch() || [];

    const accepted = projectTaskAttachmentFiles?.find((item: IFileAttachment) => {
      const fileNeedApprove = item.status === ATTACHMENT_STATUS.WAITING && item.createdBy !== user?.id && item.createdBy;
      return fileNeedApprove;
    });
    return !!accepted;
  };

  const getItems: () => CollapseProps['items'] = () => [
    {
      key: ACTIVE_KEY.DETAIL,
      label: (
        <span className='subtitle-18' id={ACTIVE_KEY.DETAIL}>
          {t('project:template_layout:task_content')}
        </span>
      ),
      extra: form.formState.errors.detail && !activeKey.includes(ACTIVE_KEY.DETAIL) && (
        <Tooltip placement='left' title={t('collapse:collapse_tooltip')}>
          <WarningCircle className='text-gray3' />
        </Tooltip>
      ),
      children: (
        <EditorWithCounter
          required
          name='detail'
          limit={NOTE_MAX_LENGTH}
          defaultValue={form.getValues('detail')}
          placeholder={String(t('placeholder:text_box', { field: String(t('project:template_layout:task_content')) }))}
          editorWrapperProps={{ className: '!h-[200px]' }}
        />
      )
    },
    {
      key: ACTIVE_KEY.ATTACHMENT,
      label: (
        <span id={ACTIVE_KEY.ATTACHMENT} className='subtitle-18'>
          {t('basic_information:attachment_file')}
        </span>
      ),
      extra: form.formState.errors.projectTaskAttachmentFiles && !activeKey.includes(ACTIVE_KEY.ATTACHMENT) && (
        <Tooltip placement='left' title={t('collapse:collapse_tooltip')}>
          <WarningCircle className='text-gray3' />
        </Tooltip>
      ),
      children: (
        <FormAttachmentTable
          name='projectTaskAttachmentFiles'
          documentType='attachments'
          isEdit={true}
          allowRemove={true}
          showTimeUpload={false}
          allowApproveReject={allowedUser()}
          requestApproval
          optionsData={{ status: ATTACHMENT_STATUS.WAITING }}
          onChange={() => {
            form.trigger('projectTaskAttachmentFiles');
          }}
        />
      )
    },
    {
      key: ACTIVE_KEY.REQUIRE_DOCUMENT,
      label: (
        <span id={ACTIVE_KEY.REQUIRE_DOCUMENT} className='subtitle-18'>
          {t('project:task_of_project:required_documents')}
        </span>
      ),
      extra: form.formState.errors.taskRequireDocuments && !activeKey.includes(ACTIVE_KEY.REQUIRE_DOCUMENT) && (
        <Tooltip placement='left' title={t('collapse:collapse_tooltip')}>
          <WarningCircle className='text-gray3' />
        </Tooltip>
      ),
      children: <TaskRequiredDocuments defaultValueForm={defaultValueForm} />
    },
    {
      key: ACTIVE_KEY.INTERNAL_DOCUMENT,
      label: (
        <span id={ACTIVE_KEY.INTERNAL_DOCUMENT} className='subtitle-18'>
          {t('template_layout:document')}
        </span>
      ),
      children: <TaskInternalDocuments defaultValueForm={defaultValueForm} />
    }
  ];
  const headerRef = useRef<HTMLDivElement>(null);
  const sizes = useResize({ elRef: headerRef, delay: 1000 });
  const wrapperRef = useRef<HTMLDivElement>(null);
  const detectResizeCommentWidget = debounce(() => {
    if (!sizes?.height || !wrapperRef?.current) return;
    wrapperRef.current?.style?.setProperty('--header-height', `${sizes.height}px`);
    wrapperRef.current?.style?.setProperty('--out-height', `${sizes.height + 98}px`);
  }, 500);

  useEffect(() => {
    detectResizeCommentWidget();
  }, [sizes]);

  const goToViewPage = async () => {
    if (!hasComment) {
      setTimeout(() => {
        navigate(VIEW_TASK_OF_PROJECT_URL(idProject ?? '', idTask ?? ''));
      }, 500);
      setHasComment(false);
    } else {
      const showPopup = await showBasePopup({
        type: 'discard',
        title: t('common:MSG_C_005:title') ?? '',
        msg: t('common:MSG_C_005:description') ?? ''
      });
      if (showPopup !== 'confirm') return;
      setTimeout(() => {
        navigate(VIEW_TASK_OF_PROJECT_URL(idProject ?? '', idTask ?? ''));
      }, 500);
      setHasComment(false);
    }
  };
  return (
    <div ref={wrapperRef} className={classNames('form-add-task-of-project flex flex-col gap-4 h-full', idTask ? 'edit-mode' : 'add-mode')}>
      <TaskDetailContext.Provider value={{ isWarningDeadline, setIsWarningDeadline }}>
        <div className='edit-task-detail-project__header'>
          {modeAdd && (
            <div className='flex justify-between'>
              <div className='title-24 whitespace-pre-line font-bold text-24'>{t('project:task_of_project:add_title')}</div>
              <Space size={16}>
                <div className='min-w-[120px]'>
                  <BaseButton
                    block
                    size='medium'
                    type='tertiary'
                    onClick={() => {
                      setTimeout(() => {
                        navigate(VIEW_PROJECT_URL(String(idProject)));
                      }, 500);
                    }}
                  >
                    {t('button:cancel')}
                  </BaseButton>
                </div>
                <div className='min-w-[120px]'>
                  <BaseButton block size='medium' disabled={loading} onClick={handleCreateTask}>
                    <span> {t('button:keep')} </span>
                  </BaseButton>
                </div>
              </Space>
            </div>
          )}
          {!modeAdd ? (
            <FormProvider {...formHeader}>
              <HeaderTaskDetail ref={headerRef} detailData={{ ...project, ...detailTask }} fetchData={fetchDetailTask} />
            </FormProvider>
          ) : (
            <></>
          )}
        </div>
        <FormProvider {...form}>
          <div className={'edit-task-detail-project__content flex flex-col gap-4'}>
            {modeAdd && <FormGroupTitleCategoryProcessOfTask />}
            {(modeAdd && form.watch('taskMasterId')) || !modeAdd ? (
              <div className='border border-solid border-gray2 rounded-lg bg-white py-[12px]'>
                {!!idTask && (
                  <div className='flex items-center gap-[8px] justify-between px-4'>
                    <FormProvider {...formHeader}>
                      <TaskDetailQuickUpdateForm detailData={detailTask} fetchData={fetchDetailTask} />
                    </FormProvider>
                    <div className='flex flex-row gap-4 justify-end'>
                      <div className='min-w-[120px]'>
                        <BaseButton block size='medium' type='tertiary' onClick={() => goToViewPage()}>
                          {t('button:cancel')}
                        </BaseButton>
                      </div>
                      <div className='min-w-[120px]'>
                        <BaseButton block size='medium' disabled={loading} onClick={handleEditTask}>
                          {t('button:keep')}
                        </BaseButton>
                      </div>
                    </div>
                  </div>
                )}
                <Collapse
                  className='py-2'
                  collapsible='header'
                  ghost
                  activeKey={activeKey}
                  onChange={handleChange}
                  items={getItems()}
                  expandIcon={({ isActive }) => renderExpandIcon(isActive)}
                />
              </div>
            ) : (
              <></>
            )}
          </div>
        </FormProvider>
      </TaskDetailContext.Provider>
      {!modeAdd && detailTask?.projectProcessId && (
        <CommentWidget.Container
          projectId={String(idProject)}
          projectProcessId={detailTask?.projectProcessId}
          taskId={String(idTask)}
          taskType={detailTask?.taskType}
          setHasComment={setHasComment}
          className='from-container-chat'
        />
      )}
      <ConfirmCancelEditing condition={(isDirty && !isSubmitted) || hasComment} />
    </div>
  );
}
