import i18n from '../../i18n';
import { IFilterForm } from '../interfaces/form';

export const API_URL = process.env.REACT_APP_API_URL;
export const API_PUBLIC_URL = process.env.REACT_APP_API_PUBLIC_URL ?? process.env.REACT_APP_API_URL;
export const API_URL_WITH_PORT = process.env.REACT_APP_API_URL_WITH_PORT;
export const REQUIRED_DOT = '(*)';
export const TIMER_RESEND_CODE = 2 * 60;
export const TIMER_RESEND_EMAIL = 2 * 60;
export const FIELDS_INPUT_CODE = 6;
export const AXIOS_TIMEOUT = 1000 * 60; // 1 minute
export const AXIOS_TIMEOUT_UPLOAD = 1000 * 60 * 60; // 60 minutes
export const AXIOS_TIMEOUT_QUESTIONNAIRE = 1000 * 60 * 10; // 10 minutes
export const KEY_CODE = {
  backspace: 'Backspace',
  left: 'ArrowLeft',
  right: 'ArrowRight'
};
export const ALLOW_KEYS_DATE = [8, 9, 37, 39];
export const FORMAT_DATE_EN = 'YYYY/MM/DD';
export const FORMAT_DATE_EN_HH_MM = 'YYYY/MM/DD HH:mm';
export const FORMAT_DATE_EN_HH_MM_SS = 'YYYY/MM/DD HH:mm:ss';
export const FORMAT_DATE_TIME_EN = 'YYYY/MM/DD';
export const FORMAT_DATE_JP = 'YYYY/MM/DD';
export const FORMAT_DATE_FILTER = 'YYYY-MM-DDTHH:mm:ss:SSS';
export const FORMAT_DATE_PAYLOAD = 'YYYY-MM-DD';
export const FORMAT_TIME = 'HH:mm';

// Max width display
export const RADIO_MAX_WIDTH_DISPLAY = 100;

// Field length
export const TEXT_FIELD_MIN_LENGTH = 8;
export const TEXT_FIELD_SHORT_MAX_LENGTH = 128;
export const TEXT_FIELD_MAX_LENGTH = 255;
export const TITLE_FIELD_MAX_LENGTH = 256;
export const STEP_NAME_MAX_LENGTH = 320;
export const MEMO_MAX_LENGTH = 2048;
export const TEXT_AREA_MAX_LENGTH = 512;
export const SELECT_TEXT_MAX_LENGTH = 128;
export const CONTENT_TEXT_MAX_LENGTH = 1024;
export const NOTE_FILED_MAX_LENGTH = 500;
export const SHORT_MEMO_MAX_LENGTH = 175;
export const NOTE_MAX_LENGTH = 1000;
export const NOTE_ANNOUNCEMENT_MAX_LENGTH = 10000;
export const NOTE_BASIC_INFO_MAX_LENGTH = 3500;
export const NOTE_MIN_LENGTH = 1;
export const SEARCH_MAX_LENGTH = 128;
export const CONTENT_MAX_LENGTH = 3000;
export const TEXT_FIELD_NUMBER_MAX_LENGTH = 10;
export const TEXT_FIELD_PHONE_NUMBER_MAX_LENGTH = 15;

// Params default
export const DEFAULT_PERPAGE = 50;
export const DEFAULT_PERPAGE_OPTIONS = 100;
export const DEFAULT_SORT_OPTIONS = 'createdDate=asc';
export const DEFAULT_MAX_ITEM_ALLOW = 100000;
export const SORT_LATEST = 'updatedDate=desc';
// User roles
export const INTERNAL_ADMIN = 'INTERNAL_ADMIN';
export const INTERNAL_GENERAL_USER = 'INTERNAL_GENERAL_USER';
export const EXTERNAL_ADMIN = 'EXTERNAL_ADMIN';
export const EXTERNAL_GENERAL_USER = 'EXTERNAL_GENERAL_USER';
export const ALL_ROLE = [INTERNAL_ADMIN, INTERNAL_GENERAL_USER, EXTERNAL_ADMIN, EXTERNAL_GENERAL_USER];
export const ALL_TASK_PROJECT_LIST_ROLE = [INTERNAL_ADMIN, INTERNAL_GENERAL_USER];

// Question length
export const MAX_OPTIONS = 20;
export const MAX_SECTIONS = 10;
export const MAX_OBJECTS = 10;
export const MAX_QUESTIONS = 30;
export const MAX_QUESTIONS_CHILDREN = 15;
export const OPTION_OTHER = 'その他...';
export const OTHER_LABEL = 'その他:';
export const OTHER_VIEW = 'Other__________';

// validate date
export const MAX_DATE = '9998/12/31';
export const MIN_DATE = '1900/01/01';

// validate file type
export const ALLOW_ALL_FILE = 'txt/doc/docx/xls/xlsx/pdf/ppt/pptx/png/jpg/jpeg';

// validate email
export const EMAIL_REGEX = /^(?!.*[._+]{2})[a-zA-Z0-9]+[A-Za-z0-9]*(?:[!#$%&'*+=?^_`{|.\/-][A-Za-z0-9]+)*[A-Za-z0-9]*@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/; // NOSONAR

// validate url link
export const URL_REGEX = /(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/; // NOSONAR

// enum
export enum SHOW_POPUP {
  CONFIRM = 'confirm',
  CANCEL = 'cancel',
  CLOSE = 'close'
}

// select option
const t = (key: string) => i18n.t(key);

export const submissionMethodOptions = [
  { value: t('required_documents:submission_method_option1'), label: t('required_documents:submission_method_option1') },
  { value: t('required_documents:submission_method_option2'), label: t('required_documents:submission_method_option2') },
  { value: t('required_documents:submission_method_option3'), label: t('required_documents:submission_method_option3') },
  { value: t('required_documents:submission_method_option4'), label: t('required_documents:submission_method_option4') },
  { value: t('required_documents:submission_method_option5'), label: t('required_documents:submission_method_option5') },
  { value: t('required_documents:submission_method_option6'), label: t('required_documents:submission_method_option6') }
];
export const submitterOptions = [
  { value: t('required_documents:submmitter_option1'), label: t('required_documents:submmitter_option1') },
  { value: t('required_documents:submmitter_option2'), label: t('required_documents:submmitter_option2') },
  { value: t('required_documents:submmitter_option3'), label: t('required_documents:submmitter_option3') },
  { value: t('required_documents:submmitter_option4'), label: t('required_documents:submmitter_option4') },
  { value: t('required_documents:submmitter_option5'), label: t('required_documents:submmitter_option5') },
  { value: t('required_documents:submmitter_option6'), label: t('required_documents:submmitter_option6') }
];
export const submmitterFilter = [
  { value: t('required_documents:submmitter_option1'), text: t('required_documents:submmitter_option1') },
  { value: t('required_documents:submmitter_option2'), text: t('required_documents:submmitter_option2') },
  { value: t('required_documents:submmitter_option3'), text: t('required_documents:submmitter_option3') },
  { value: t('required_documents:submmitter_option4'), text: t('required_documents:submmitter_option4') },
  { value: t('required_documents:submmitter_option5'), text: t('required_documents:submmitter_option5') },
  { value: t('required_documents:submmitter_option6'), text: t('required_documents:submmitter_option6') }
];
export const confirmedFilter = [
  { value: t('required_documents:confirm'), text: t('required_documents:confirm') },
  { value: t('required_documents:done'), text: t('required_documents:done') }
];

export const TYPE_NOTIFICATION = {
  PROJECT: 'PROJECT.ASSIGN',
  TASK: 'TASK.ASSIGN',
  APPROVAL_RESULT: 'DOCUMENT.APPROVAL_RESULT',
  APPROVAL_REQUEST: 'DOCUMENT.APPROVAL_REQUEST',
  EXTERNAL: 'EXTERNAL'
};

export const TASK_STATUS = ['not_supported', 'in_progress', 'done'];
export const MAX_LENGTH_TABLE = 50;
export const DURATION = 3100;
export const MAX_CAPACITY_FILE = 30;
export const MAX_NUMBER_FILE = 10;

// error BE
export const ERROR_DUPLICATE = 'ERROR.ENTITY.DUPLICATION';
export const ERROR_EMAIL_DUPLICATE = 'ERROR.USER.EMAIL_IS_DUPLICATION';
export const ERROR_COMPANY_NOTFOUND = 'ERROR.ENTITY.NOT_FOUND';
export const ERROR_NETWORK = 'ERR_NETWORK';
export const DEFAULT_SECTION_NAME = 'Default-Section';
export const DEFAULT_NAME_OBJECT = '対象者名';
export const ERROR_VALIDATION = 'ERROR.VALIDATION.FAILED';
export const ERROR_ITEMS_DELETED = 'ERROR.ENTITY.VALIDATION.FIELD_NOT_FOUND';
export const LOAD_PAGE_ITEM = 3;
export const DEFAULT_WIDTH_TAB_ITEM = 226;

export const SIGNALR_METHODS = {
  UNREGISTER_USER_UPN: 'UnregisterUserUpn',
  REGISTER_USER_UPN: 'RegisterUserUpn',
  REGISTER_GROUP: 'RegisterGroup',
  UNREGISTER_GROUP: 'UnregisterGroup',
  REGISTER_LIST_GROUP: 'RegisterListGroup',
  UNREGISTER_LIST_GROUP: 'UnregisterListGroup',
  RECEIVE_USER_MESSAGE: 'ReceiveUserMessage',
  RECEIVE_MESSAGE: 'ReceiveMessage',
  RECEIVE_GROUP_MESSAGE: 'ReceiveGroupMessage'
};

export const PROJECT_FIELDS_SEARCH = ['code', 'picName', 'applicantCompanyNameSearch', 'assignmentCompanyNameSearch'];
export const TEMPLATE_FIELDS_SEARCH = ['nameSearch', 'updatedNameSearch'];
export const ACCOUNT_MASTER_FIELDS_SEARCH = ['nameSearch', 'updatedNameSearch'];
export const COMPANY_FIELDS_SEARCH = ['nameSearch', 'updatedNameSearch'];
export const TASK_FIELDS_SEARCH = ['nameSearch', 'remarkSearch', 'updatedNameSearch'];
export const DOCUMENT_FIELDS_SEARCH = ['nameSearch', 'noteSearch', 'submissionMethodNameSearch', 'updatedNameSearch', 'memoSearch'];
export const ACCOUNT_FIELDS_SEARCH = ['userNameSearch', 'email', 'updatedNameSearch'];
export const TASK_ALL_PROJECT_FIELDS_SEARCH = ['code', 'taskNameSearch', 'travelNameSearch', 'companyNameSearch', 'remarkSearch'];
export const ANNOUNCEMENT_FIELDS_SEARCH = ['titleSearch'];
export const PHRASE_FIELDS_SEARCH = ['titleSearch', 'updatedNameSearch'];

export enum UPLOAD_STATUS {
  DONE = 'done',
  UPLOADING = 'uploading',
  ERROR = 'error'
}

export const COMMENT_CONTENT_KEY = 'CommentContent';

export const ERROR_CODE: { [index: string]: string } = {
  ERR_NETWORK: 'ERR_NETWORK',
  IN_USED: 'ERROR.VALIDATION.USED',
  MOT_DOESNT_DELETE: 'COMPANY.REMOVE.MOT_DOESNT_DELETE',
  COMMON_EXCEPTION: 'COMMON_EXCEPTION',
  FORBIDDEN_ACCESS_DENIED: 'FORBIDDEN_ACCESS_DENIED',
  SECURITY_EXCEPTION: 'SECURITY_EXCEPTION',
  NOT_FOUND: 'NOT_FOUND',
  VALIDATION: 'ERROR.ENTITY.VALIDATION',
  VERSION_MISMATCH: 'ERROR.VALIDATION.VERSION.MISMATCH',
  USER_INACTIVE: 'ERROR.USER.INACTIVE',
  USER_RESTRICT: 'ERROR.USER.RESTRICT',
  USER_WAITING_CREATE: 'ERROR.USER.WAITING_CREATE',
  FIELD_NOT_FOUND: 'ERROR.ENTITY.VALIDATION.FIELD_NOT_FOUND',
  VALIDATION_FAILED: 'ERROR.VALIDATION.FAILED',
  VALIDATION_FIELD_NOT_FOUND: 'VALIDATION.FIELD_NOT_FOUND',
  PROJECT_MOT_PIC_INACTIVE: 'ERROR.PROJECT.MOT_PIC.INACTIVE',
  PROJECT_NOT_READY: 'ERROR.PROJECT_STATUS.NOT_READY',
  FILE_NAME_TOO_LARGE: 'FILE_NAME_TOO_LARGE',
  ENTITY_NOT_FOUND: 'ERROR.ENTITY.NOT_FOUND',
  ENTITY_REJECTED: 'ERROR.USER.REJECTED',
  ERR_BAD_RESPONSE: 'ERR_BAD_RESPONSE',
  ERR_BAD_REQUEST: 'ERR_BAD_REQUEST',
  USER_NOT_ASSIGN_IN_PROJECT: 'ERROR.ANNOUNCEMENT.ADD.USER_NOT_ASSIGN_IN_PROJECT',
  VALIDATION_FIELD_REQUIRED: 'ERROR.ENTITY.VALIDATION.FIELD_REQUIRED',
  PROJECT_MEMO_EXISTED: 'ERROR.VALIDATION.PROJECT_MEMO_EXISTED',
  VALIDATION_FILE_FORMAT: 'ERROR.FILE.FORMAT',
  VALIDATION_FILE_MAX_ROW: 'ERROR.MAX.ROW',
  VALIDATION_FILE_FORMAT_CONTENT: 'ERROR.FILE.FORMAT.CONTENT',
  VALIDATION_FILE_SIZE: 'ERROR.FILE.SIZE',
  BASIC_INFO_DONT_HAVE_DOB: 'ERROR.VALIDATION.APPLICANT.INFORMATION'
};

export const ERROR_DETAIL: { [index: string]: string } = {
  FILE_EXTENSION_IGNORE: 'STORAGE.FILE.UPLOAD.EXTENSION_IGNORE',
  FILE_OVER_SIZE: 'STORAGE.FILE.UPLOAD.FILE_TOO_LARGE',
  DOCUMENT_TYPE_NOT_SUPPORT: 'STORAGE.FILE.UPLOAD.DOCUMENT_TYPE_NOT_SUPPORT',
  FILE_TOO_SMALL: 'STORAGE.FILE.UPLOAD.FILE_TOO_SMALL',
  FILE_NAME_OVER_SIZE: 'STORAGE.FILE.UPLOAD.FILE_NAME_TOO_LARGE'
};

export const MAPPING_ERROR_CODE_KEY: { [key: string]: string } = {
  [ERROR_DUPLICATE]: 'common:MSG_C_019',
  [ERROR_EMAIL_DUPLICATE]: 'common:MSG_A_011',
  [ERROR_DETAIL.FILE_NAME_OVER_SIZE]: 'common:MSG_C_030',
  [ERROR_DETAIL.DOCUMENT_TYPE_NOT_SUPPORT]: 'common:MSG_C_008',
  [ERROR_DETAIL.FILE_EXTENSION_IGNORE]: 'common:MSG_C_008',
  [ERROR_DETAIL.FILE_OVER_SIZE]: 'common:MSG_C_006',
  [ERROR_CODE.VERSION_MISMATCH]: 'common:MSG_C_015',
  [ERROR_CODE.IN_USED]: 'common:MSG_C_026',
  [ERROR_CODE.USER_INACTIVE]: 'common:MSG_C_015',
  [ERROR_CODE.USER_NOT_ASSIGN_IN_PROJECT]: 'common:MSG_C_015',
  [ERROR_CODE.FIELD_NOT_FOUND]: 'common:MSG_C_015',
  [ERROR_CODE.VALIDATION_FIELD_REQUIRED]: 'common:MSG_P_030',
  [ERROR_CODE.PROJECT_MEMO_EXISTED]: 'common:MSG_C_015',
  [ERROR_CODE.BASIC_INFO_DONT_HAVE_DOB]: 'common:MSG_P_023',
  [ERROR_CODE.VALIDATION_FILE_FORMAT]: 'common:MSG_V_011',
  [ERROR_CODE.VALIDATION_FILE_SIZE]: 'common:MSG_C_006',
  [ERROR_CODE.VALIDATION_FILE_MAX_ROW]: 'common:MSG_V_010',
  [ERROR_CODE.VALIDATION_FILE_FORMAT_CONTENT]: 'common:MSG_C_014'
};
export const DEBOUNDS_TIME = 300;

export const KEYS_FILTER: { [index: string]: string } = {
  projectList: 'projectList',
  accountMasterList: 'accountMasterList',
  templateList: 'templateList',
  allTasksProject: 'allTasksProject',
  companyList: 'companyList',
  taskList: 'taskList',
  documentList: 'documentList',
  phraseList: 'phraseList',
  accountList: 'accountList',
  announcementList: 'announcementList',
  todoListOfProject: 'todoListOfProject',
  customerRequestListOfProject: 'customerRequestListOfProject'
};

export const DEFAULT_VALUES_FILTER: { [index: string]: IFilterForm } = {
  [KEYS_FILTER.projectList]: {
    countryId: {
      label: '',
      value: [],
      type: 'guid',
      operation: 'in'
    },
    statusId: {
      label: '',
      value: '',
      type: 'text',
      operation: 'eq'
    },
    picId: {
      label: '',
      value: [],
      type: 'guid',
      operation: 'in'
    },
    createdDate: {
      label: '',
      value: '',
      type: 'date',
      operation: 'eq'
    },
    departureDateFrom: {
      label: '',
      value: '',
      type: 'nullable_date',
      operation: 'gte',
      fieldTitle: 'departureDate'
    },
    departureDateTo: {
      label: '',
      value: '',
      type: 'nullable_date',
      operation: 'lte',
      fieldTitle: 'departureDate'
    },
    categoryId: {
      label: '',
      value: '',
      type: 'text',
      operation: 'eq'
    },
    arrangementTargetCode: {
      label: '',
      value: '',
      type: 'text',
      operation: 'eq'
    },
    applicantCompanyId: {
      label: '',
      value: '',
      type: 'text',
      operation: 'eq'
    }
  },
  [KEYS_FILTER.templateList]: {
    name: {
      label: '',
      value: '',
      type: 'text',
      operation: 'sw'
    },
    countryId: {
      label: '',
      value: '',
      type: 'text',
      operation: 'eq'
    },
    categoryId: {
      label: '',
      value: '',
      type: 'text',
      operation: 'eq'
    },
    patternId: {
      label: '',
      value: '',
      type: 'text',
      operation: 'eq'
    },
    formId: {
      label: '',
      value: '',
      type: 'text',
      operation: 'eq'
    },
    createdUtc: {
      label: '',
      value: '',
      type: 'nullable_date',
      operation: 'eq'
    },
    dateFrom: {
      label: '',
      value: '',
      type: 'nullable_date',
      operation: 'gte',
      fieldTitle: 'updatedDate'
    },
    dateTo: {
      label: '',
      value: '',
      type: 'nullable_date',
      operation: 'lte',
      fieldTitle: 'updatedDate'
    }
  },
  [KEYS_FILTER.allTasksProject]: {
    managerId: {
      label: '',
      value: [],
      type: 'guid',
      operation: 'in'
    },
    countryId: {
      label: '',
      value: [],
      type: 'guid',
      operation: 'in'
    },
    status: {
      label: '',
      value: '',
      type: 'text',
      operation: 'eq'
    },
    dateFrom: {
      label: '',
      value: '',
      type: 'nullable_date',
      operation: 'gte',
      fieldTitle: 'deadline'
    },
    dateTo: {
      label: '',
      value: '',
      type: 'nullable_date',
      operation: 'lte',
      fieldTitle: 'deadline'
    }
  },
  [KEYS_FILTER.companyList]: {
    dateFrom: {
      label: '',
      value: '',
      type: 'nullable_date',
      operation: 'gte',
      fieldTitle: 'updatedDate'
    },
    dateTo: {
      label: '',
      value: '',
      type: 'nullable_date',
      operation: 'lte',
      fieldTitle: 'updatedDate'
    }
  },
  [KEYS_FILTER.taskList]: {
    countryId: {
      label: '',
      value: '',
      type: 'text',
      operation: 'eq'
    },
    dateFrom: {
      label: '',
      value: '',
      type: 'nullable_date',
      operation: 'gte',
      fieldTitle: 'updatedDate'
    },
    dateTo: {
      label: '',
      value: '',
      type: 'nullable_date',
      operation: 'lte',
      fieldTitle: 'updatedDate'
    }
  },
  [KEYS_FILTER.documentList]: {
    countryId: {
      label: '',
      value: '',
      type: 'text',
      operation: 'eq'
    },
    submitterId: {
      label: '',
      value: '',
      type: 'text',
      operation: 'eq'
    },
    dateFrom: {
      label: '',
      value: '',
      type: 'nullable_date',
      operation: 'gte',
      fieldTitle: 'updatedDate'
    },
    dateTo: {
      label: '',
      value: '',
      type: 'nullable_date',
      operation: 'lte',
      fieldTitle: 'updatedDate'
    }
  },
  [KEYS_FILTER.phraseList]: {
    categoryId: {
      label: '',
      value: '',
      type: 'text',
      operation: 'eq'
    },
    dateFrom: {
      label: '',
      value: '',
      type: 'nullable_date',
      operation: 'gte',
      fieldTitle: 'updatedDate'
    },
    dateTo: {
      label: '',
      value: '',
      type: 'nullable_date',
      operation: 'lte',
      fieldTitle: 'updatedDate'
    }
  },
  [KEYS_FILTER.accountList]: {
    userName: {
      label: '',
      value: '',
      type: 'text',
      operation: 'contains'
    },
    organizationName: {
      label: '',
      value: '',
      type: 'text',
      operation: 'eq'
    },
    roleName: {
      label: '',
      value: '',
      type: 'text',
      operation: 'eq'
    },
    email: {
      label: '',
      value: '',
      type: 'text',
      operation: 'contains'
    },
    status: {
      label: '',
      value: '',
      type: 'text',
      operation: 'eq'
    },
    dateFrom: {
      label: '',
      value: '',
      type: 'nullable_date',
      operation: 'gte',
      fieldTitle: 'updatedDate'
    },
    dateTo: {
      label: '',
      value: '',
      type: 'nullable_date',
      operation: 'lte',
      fieldTitle: 'updatedDate'
    }
  },
  [KEYS_FILTER.announcementList]: {
    title: {
      label: '',
      value: '',
      type: 'text',
      operation: 'eq'
    },
    companyName: {
      label: '',
      value: '',
      type: 'text',
      operation: 'eq'
    },
    updateBy: {
      label: '',
      value: '',
      type: 'text',
      operation: 'eq'
    },
    dateFrom: {
      label: '',
      value: '',
      type: 'nullable_date',
      operation: 'gte',
      fieldTitle: 'updatedDate'
    },
    dateTo: {
      label: '',
      value: '',
      type: 'nullable_date',
      operation: 'lte',
      fieldTitle: 'updatedDate'
    }
  },
  [KEYS_FILTER.todoListOfProject]: {
    manager: {
      label: '',
      value: [],
      type: 'text',
      operation: 'eq'
    },
    status: {
      label: '',
      value: '',
      type: 'text',
      operation: 'eq'
    },
    dateFrom: {
      label: '',
      value: '',
      type: 'date',
      operation: 'gte'
    },
    dateTo: {
      label: '',
      value: '',
      type: 'date',
      operation: 'lte'
    }
  },
  [KEYS_FILTER.customerRequestListOfProject]: {
    taskPic: {
      label: '',
      value: '',
      type: 'text',
      operation: 'eq'
    },
    createdBy: {
      label: '',
      value: '',
      type: 'text',
      operation: 'eq'
    },
    status: {
      label: '',
      value: '',
      type: 'text',
      operation: 'eq'
    },
    deadlineFrom: {
      label: '',
      value: '',
      type: 'nullable_date',
      operation: 'gte',
      fieldTitle: 'deadline'
    },
    deadlineTo: {
      label: '',
      value: '',
      type: 'nullable_date',
      operation: 'lte',
      fieldTitle: 'deadline'
    }
  },
  [KEYS_FILTER.accountMasterList]: {
    organizationId: {
      label: '',
      value: '',
      type: 'text',
      operation: 'eq'
    },
    roleId: {
      label: '',
      value: '',
      type: 'text',
      operation: 'eq'
    },
    status: {
      label: '',
      value: '',
      type: 'text',
      operation: 'eq'
    },
    updateDateFrom: {
      label: '',
      value: '',
      type: 'date',
      operation: 'gte'
    },
    updateDateTo: {
      label: '',
      value: '',
      type: 'date',
      operation: 'lte'
    }
  }
};

export const LOCALSTORAGE = {
  USER: 'user',
  ACCESS_TOKEN: 'access_token',
  REFRESS_TOKEN: 'refresh_token',
  STATUS_COLLAPSED: 'statusCollapsed',
  FILTERED_VALUES: 'filteredValues',
  ID_CUSTOMER_REQUEST: 'idCustomerRequest',
  FILTER_ANNOUNCEMENT: 'filterAnnouncement',
  FILTER_QUERY_STRING: 'filterQueryString'
};

export const DEFAULT_PAGE = '/projects';

export const PAGE_SIZE_OPTIONS: number[] = [50, 100, 150];

export const LIST_API_EXCEPTIONS = ['/usr/users/email/'];

export enum DUE_DATE {
  WARNING = 'warning',
  DANGER = 'danger',
  CONFIRMED = 'confirmed'
}

export enum TYPE {
  SAVE = 'save',
  DISCARD = 'discard',
  SUCCESS = 'success',
  REQUIRED = 'required',
  ERROR = 'error',
  CONFIRM = 'confirm'
}

export const TEXT_COLORS = ['#000000', '#e60000', '#ff9900', '#ffff00', '#008a00', '#0066cc', '#9933ff'];
export const BACK_GROUND_COLORS = ['white', '#e60000', '#ff9900', '#ffff00', '#008a00', '#0066cc', '#9933ff'];
export const NOTICE_COLORS = ['#FE2712', '#FD6F08', '#FB0288', '#F9BC02', '#C1DC11', '#A7194B', '#8601B0', '#3E01A4', '#0291CD', '#55B313'];
export const CONFIG_DOM_PURIFY = {
  FORBID_TAGS: ['img']
};

export const MODE_VIEW_ATTACHMENT = {
  VIEW: 'view',
  EDIT: 'edit'
};
