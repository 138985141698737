import { QUERY_CONDITION, QUERY_OPERATION, QUERY_TYPE } from '@/utils/constants';
import QueryFactory, { QueryItem } from '@/utils/helpers/filters/base';
import { convertFullWidthToHalfWidth, getEndOfDay } from '@/utils/helpers/globalHelper';

import { IQueryFilterParams } from '../models';

export class ProjectListQueryFactory extends QueryFactory {
  getByMyProject(value: any[], field: string[]) {
    const condition = this.data?.length ? QUERY_CONDITION.AND : QUERY_CONDITION.EMPTY;
    const newQuerySection = this.initNewQuery(condition);
    const operations = [QUERY_OPERATION.IN, QUERY_OPERATION.EQUAL];
    const conditions = [QUERY_CONDITION.EMPTY, QUERY_CONDITION.OR];
    for (let i = 0; i < field.length; i++) {
      newQuerySection.childrens?.push(
        new QueryItem(field[i], QUERY_TYPE.GUID, i === 0 ? value[i]?.join(';') || '' : value[i], operations[i], conditions[i])
      );
    }
    this.append(newQuerySection);
    return this;
  }
  getById(value: string, field: string) {
    const newQuerySection = this.initNewQuery(this.data?.length ? QUERY_CONDITION.AND : QUERY_CONDITION.EMPTY);
    const idQuery = new QueryItem().modules.guidWith(value, { fieldTitle: field });
    newQuerySection.childrens?.push(idQuery);
    this.append(newQuerySection);
    return this;
  }
  getByIds(value: string[], field: string) {
    const newQuerySection = this.initNewQuery(this.data?.length ? QUERY_CONDITION.AND : QUERY_CONDITION.EMPTY);
    const idQuery = new QueryItem(field, QUERY_TYPE.GUID, value?.join(';') ?? '', QUERY_OPERATION.IN, QUERY_CONDITION.AND);
    newQuerySection.childrens?.push(idQuery);
    this.append(newQuerySection);
    return this;
  }
  getByDateForm(date: string, fieldTitle: string, isDepartureDefault: boolean = false) {
    const newQuerySection = this.initNewQuery(this.data?.length ? QUERY_CONDITION.AND : QUERY_CONDITION.EMPTY);
    const queryItems = [new QueryItem().modules.fromDate(date, { fieldTitle })];
    if (isDepartureDefault) {
      queryItems.push(new QueryItem(fieldTitle, QUERY_TYPE.NULLABLE_DATE, '', QUERY_OPERATION.EQUAL, QUERY_CONDITION.OR));
    }
    newQuerySection?.childrens?.push(...queryItems);
    this.append(newQuerySection);
    return this;
  }
  getByDateTo(date: string, fieldTitle: string) {
    const newQuerySection = this.initNewQuery(this.data?.length ? QUERY_CONDITION.AND : QUERY_CONDITION.EMPTY);
    newQuerySection?.childrens?.push(new QueryItem().modules.toDate(date, { fieldTitle }));
    this.append(newQuerySection);
    return this;
  }
}

export const generateFilter = (params?: IQueryFilterParams): string => {
  const filterFactory = new ProjectListQueryFactory();
  if (params?.keyword) {
    const fields = [
      'code.ToLower()',
      'codeSearch.ToLower()',
      'picName.ToLower()',
      'applicantCompanyNameSearch.ToLower()',
      'assignmentCompanyNameSearch.ToLower()',
      'applicantNameSearch.ToLower()',
      'applicantNameRomajiSearch.ToLower()',
      'applicantNameTrimSearch.ToLower()',
      'applicantNameRomajiTrimSearch.ToLower()'
    ];
    filterFactory.searchingBy(fields, convertFullWidthToHalfWidth(params.keyword).toLowerCase());
  }
  if (params?.countryIds?.length) {
    filterFactory.getByIds(params.countryIds, 'ApplicantCountryId');
  }
  if (params?.categoryId) {
    filterFactory.getById(params.categoryId, 'VisaCategoryId');
  }
  if (params?.picIds?.length && params?.isDraft) {
    filterFactory.getByMyProject([params.picIds, params.isDraft], ['PicId', 'IsDraft']);
  } else if (params?.picIds?.length) {
    filterFactory.getByIds(params.picIds, 'PicId');
  }
  if (params?.companyId) {
    filterFactory.getById(params.companyId, 'ApplicantCompanyId');
  }
  if (params?.status) {
    filterFactory.getById(params.status, 'StatusId');
  }
  if (params?.departureFrom) {
    filterFactory.getByDateForm(params.departureFrom, 'DepartureDate');
  } else if (params?.departureDefault) {
    filterFactory.getByDateForm(params.departureDefault, 'DepartureDate', true);
  }
  if (params?.departureTo) {
    filterFactory.getByDateTo(getEndOfDay(params.departureTo), 'DepartureDate');
  }
  if (params?.createDateFrom) {
    filterFactory.getByDateForm(params.createDateFrom, 'CreatedDate');
  }
  if (params?.createDateTo) {
    filterFactory.getByDateTo(getEndOfDay(params.createDateTo), 'CreatedDate');
  }
  return filterFactory.sanitize().stringifyData();
};
